<template>
    <panelEagle id='Cadastro-de-Novo-Usuário-App' :loading='loadingPanelEagle'>
        <div class="col-sm-12  nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Colaboradores' :icon='mdiAccountSupervisor'>
                    </tituloPage>
                </div>
                <div class="col-sm-6 row divDontPrint">
                    <basicButtonsCadastrosSC linkCancel="listarMotoristas" :disabled='$v.$invalid' tipoCancela="button"
                        @salvarCadastro="salvarCadastro">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12">
                <hr>
            </div>
            <div class="col-sm-12">
                <tabsEagle :tabs="tabs" ref="refTabs" v-model="modelTab" @activate-tab="testeClickTab">
                    <slot slot="tab-dados">
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-lg-4 col-md-12 nopadding">
                                <buttonsFilters @buttonsFiltersChange="buttonsFiltersChangeStatus" label="Status"
                                    :onlyOneSelected="true" :arrayButtons="arrayButtonsStatusTabDados" />
                            </div>
                            <div class="col-lg-5 col-md-12 nopadding">
                                <buttonsFilters @buttonsFiltersChange="buttonsFiltersChangeTipo" label="Tipo"
                                    :onlyOneSelected="false" :arrayButtons="arrayButtonsTipoTabDados" />
                            </div>
                            <div class="col-lg-3 col-md-12 nopadding">
                                <selectAll ref='refSelectPontuacao' :selected="selectedPontuacao"
                                    @changeSelect="changeSelectPontuacao" nameForRadio="nameForRadioasdadasdadasd"
                                    :isMultiple="false" :hasSelectAll="false" :labels="[
                                        { indexDFH: 'PTC', description: 'Pontuação' }
                                    ]" :optionsArray="optPontuacao"
                                    :disabled='$v.valueEmpresaDadosPessoais.$invalid' />
                            </div>
                        </div>
                        <div class="col-sm-12 row margi-top-10px">
                            <div class="col-lg-4 col-md-6 col-sm-12 nopadding">
                                <selectAll @close='$v.valueEmpresaDadosPessoais.$touch()' :extraClass='{
                                    "boder border-danger": $v.valueEmpresaDadosPessoais.$anyError
                                }' :selected="selectedEmpresaDadosPessoais" nameForRadio="nameForRadio"
                                    :isMultiple="false" :hasSelectAll="false" :labels="[
                                        { indexDFH: 'EM', description: 'Empresa*' }
                                    ]" :optionsArray="optEmpresas" @changeSelect='changeSelectEmpresaDadosPessoais' />
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 nopadding">
                                <inputSimple :inputClass='{
                                    "boder border-danger": $v.valueInputNome.$anyError
                                }' @blur='$v.valueInputNome.$touch()' @changeInput="changeInputNome"
                                    :value="valueInputNome" label="Nome*" />
                            </div>
                            <div class="col-md-12 col-lg-4 row nopadding">
                                <div class="col-sm-6 nopadding">
                                    <inputSimple :inputClass='{
                                        "boder border-danger": $v.valueInputCPF.$anyError
                                    }' @blur='$v.valueInputCPF.$touch()' id='cpf' :value="valueInputCPF"
                                        @changeInput="changeInputCPF" :title="titleCPF" :hasMask="true"
                                        :mask="['NNN.NNN.NNN-NN']" label="CPF*" />
                                </div>
                                <div class="col-sm-6 nopadding">
                                    <inputSimple :value="valueInputRG" @changeInput="changeInputRG" :hasMask="true"
                                        :mask="['PPPPPPPPPPPPPPPPPP']" label="RG" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 row margi-top-10px">
                            <div class="col-lg-2 col-md-6 nopadding">
                                <inputSimple :value="valueInputTelefone" @changeInput="changeInputTelefone"
                                    :hasMask="true" :mask="['NNPNPNNNNPNNNN']" label="Telefone" />
                            </div>
                            <div class="col-lg-2 col-md-6 nopadding">
                                <inputSingleDay label="Data de nascimento" :value="valueInputDataDadosPessoais"
                                    @changeInput="changeInputDataDadosPessoais" name="inputSingleDay" />
                            </div>
                            <div class="col-lg-2 col-md-6 nopadding">
                                <inputSimple :title="tituloCartaoMotorista" id="cartaoMotorista"
                                    @changeInput="changeInputNDeCartao" :value="valueInputNDeCartao"
                                    :mask="['NNNNNNNNNN']" :hasMask="true" placeholder="Minimo 5 caracteres"
                                    label="Nº de cartão" />
                            </div>
                            <div class="col-lg-2 col-md-6 nopadding">
                                <inputSimple :title="tituloCartaoAjudante" id="cartaoAjudante"
                                    ref="refInputCartaoAjudante" @changeInput="changeInputNDeCartaoAjudante"
                                    :inputDisabled="desabilitaCartaoAjudante" :mask="['NNNNNNNNNN']" :hasMask="true"
                                    placeholder="Minimo 5 caracteres" :value="valueInputCartaoAjudante"
                                    label="Nº de cartão (ajudante)" />
                            </div>
                            <div class="col-lg-2 col-md-6 padd-rig-0 nopadding">
                                <inputSimple @changeInput="changeInputCracha" :value="valueInputCracha"
                                    label="Crachá" />
                            </div>
                            <div class="col-lg-2 col-md-6 row nopadding">
                                <div class="col-sm-12 nopadding">
                                    <inputSimple :value="valueEmail" @changeInput="changeInputEmail"
                                        class="col-sm-12 padd-lef-0" type="email" label="Email *" />
                                </div>
                                <div class="col-sm-12">
                                    <b-form-checkbox size="sm" class="col-sm-12 pad-10px"
                                        :disabled="valueEmail.length > 0 ? false : true"
                                        v-model="checkboxReceberNotificacao">
                                        <span class="texto-pequeno">
                                            Receber notificações da jornada
                                        </span>
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 row">
                            <div class="col-lg-4 col-md-12 nopadding">
                                <selectAll ref='selectJornadaTrabalho' @changeSelect="changeSelectJornadaDeTrabalho"
                                    nameForRadio="nameForRadio" :selected="selectedJornadaTrabalho" :isMultiple="false"
                                    :hasSelectAll="false" :labels="[
                                        { indexDFH: 'EM', description: 'Jornada de Trabalho' }
                                    ]" :optionsArray="optJornadaTrab"
                                    :disabled='$v.valueEmpresaDadosPessoais.$invalid' />
                            </div>

                            <div class="col-lg-4 col-md-12 row nopadding">
                                <div class="col-lg-6 col-md-12 nopadding">
                                    <inputSimple @changeInput="changeInputPIS" :value="valueInputPIS" :hasMask="true"
                                        :mask="['NNN.NNNNN.NN-N']" label="PIS" />
                                </div>
                                <div class="col-lg-6 col-md-12 nopadding">
                                    <selectAll ref='selectGrupoMotorista' :selected="selectedGrupoColaboradores"
                                        @changeSelect="changeSelectGrupoColaboradores" nameForRadio="nameForRadio"
                                        :isMultiple="false" :hasSelectAll="false" :labels="[
                                            { indexDFH: 'EM', description: 'Grupo de motoristas' }
                                        ]" :optionsArray="optGrupoDeMotorista"
                                        :disabled='$v.valueEmpresaDadosPessoais.$invalid' />
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 row nopadding">
                                <div class="col-sm-12">
                                    <b-form-checkbox class="col-sm-12 pad-10px" v-model="checkboxMultiEmpresa">
                                        Colaborador multiempresa
                                    </b-form-checkbox>
                                </div>
                                <div class="col-sm-12 nopadding">
                                    <selectAll :selected="selectedEmpresaMultipla" @changeSelect="changeEmpresaMultipla"
                                        :disabled="!checkboxMultiEmpresa" nameForRadio="nameForRadio" :isMultiple="true"
                                        :hasSelectAll="true" :optionsArray="optEmpresas" />
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 row nopadding mt-3">
                                <inputSimple @changeInput="changeInputEsocial" :value="valueInputEsocial"
                                    :hasMask="false" label="Matrícula Esocial" />
                            </div>
                        </div>
                    </slot>

                    <slot slot="tab-endereco">
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-lg-8 col-md-12 row margin-bot nopadding">
                                <inputSearch @keyupEnter="clickButtonSearchMapa" buttonDisabled='disabled'
                                    inputClass="col-sm-12 " :value="valueInputSearchMapa"
                                    @clickButtonSearch="clickButtonSearchMapa" label="Endereço" name="inputSearch" />
                            </div>
                            <div class="col-lg-2 col-md-6 nopadding margin-bot-grande">
                                <inputSimple :value="valueLatitude" label="Latitude" />
                            </div>
                            <div class="col-lg-2 col-md-6 nopadding margin-bot-grande">
                                <inputSimple :value="valueLongitude" label="Longitude" />
                            </div>
                            <div class="col-sm-12 position">
                            </div>
                        </div>
                    </slot>

                    <slot slot="tab-licencas">
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-lg-5 col-md-12 nopadding">
                                <inputSimple @changeInput="changeInputCNH" :value="valueInputCNH" :hasMask="true"
                                    :mask="['NNNNNNNNNNN']" label="Número da CNH" />
                            </div>
                            <div class="col-lg-2 col-md-12 nopadding">
                                <selectAll :value='valueTipoLicenca' :selected="selectedTipoCNH"
                                    @changeSelect="changeSelectTipoLicenca" nameForRadio="Tipo" :isMultiple="false"
                                    :hasSelectAll="false" :labels="[
                                        { indexDFH: 'TI', description: 'Tipo' }
                                    ]" :optionsArray="optTipoCarteiraLicenca" />
                            </div>
                            <div class="col-lg-5 col-md-12 nopadding">
                                <inputSingleDay :value="valueDataValidadeNumeroCNH"
                                    @changeInput="changeInputDataValidadeNumeroCNH" label="Validade"
                                    name="inputSingleDay" />
                            </div>
                        </div>
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-lg-1 col-md-4 margi-top-30px nopadding">
                                <simpleButton width="100%" event="click" @click="clickBotaoAbrirModal"
                                    :icon="mdiPlus" />
                            </div>
                            <div class="col-lg-5 col-md-8 nopadding">
                                <selectAll ref='refselectAllLicenca' @changeSelect="changeSelectLicencasECertificacao"
                                    nameForRadio="LC" :isMultiple="false" :hasSelectAll="false" :labels="[
                                        { indexDFH: 'LC', description: 'Licenças e certificações' }
                                    ]" :optionsArray="optTipoLicencaECertificacao"
                                    :disabled='$v.valueEmpresaDadosPessoais.$invalid' />
                            </div>
                            <div class="col-lg-5 col-md-8 nopadding">
                                <inputSingleDay @changeInput="changeInputDataValidadeTabela" label="Validade"
                                    name="inputSingleDay" />
                            </div>
                            <div class="col-lg-1 nopadding col-md-4 margi-top-30px">
                                <simpleButton :disabled='valueLicenca.length == 0 ? true : false' width="100px"
                                    event="click" @click="clickBotaoAdicionar" :icon="mdiCheckBold" text="Adicionar" />
                            </div>
                            <div class="col-sm-12" v-show="alertaLicencaRepetida">
                                <alertSimple :isLoading='false' :icon='mdiAlert' type='orange' message='
                                    Você já possui licenças ou certificações 
                                    cadastradas com os mesmos parâmetros
                                ' />
                            </div>
                            <div class="col-sm-12 margi-top-30px" v-show="dataTable.length" id="diferente">
                                <tableListagem @excluirCadastro="excluirCadastroTable" deleteEdit="editDelete"
                                    :data="dataTable" :titles="titlesTabela" />
                            </div>
                        </div>
                    </slot>

                    <slot slot="tab-integracao">
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-lg-4 col-md-12 nopadding">
                                <inputSimple @changeInput="(v) => valueCodgioInterfoc = v" :value="valueCodgioInterfoc"
                                    label="Código interfoc" />
                            </div>
                            <!-- :hasMask="false"
                            :mask="['N'.repeat(9)]" -->
                        </div>
                    </slot>
                </tabsEagle>
            </div>
            <div v-show="mostraMapa" class="col-sm-12 position-abs">
                <div class="col-sm-8 row div-local-tamanho padd-seletor-rua pl-3" v-show="mostraSeletorLocalMapa">
                    <b-list-group class="div-enderecos col-sm-12">
                        <b-list-group-item v-for="(dados, index) in enderecosMapa" :key="index" button
                            @click="clickListaEnderecoMapa(dados)">
                            <b-overlay :show='loadingEndereco'>
                                {{ dados.endereco }}
                            </b-overlay>
                        </b-list-group-item>
                    </b-list-group>
                </div>
                <mapaSimples height="500" class="" ref='mapaEndereco'>
                    <markerPoint icon="img/matriz.png" :isDraggable="false" />
                    <markerPoint :latitude="latitude" :longitude="longitude" @drag='dragMarker' @dragend='dragEnd'
                        :isDraggable="true">
                        <popupMarker>
                            <p>Me arraste para o local desejado. Raio:</p>
                            <basicSlider @change="changeSlider" :maxValue="500" />
                        </popupMarker>
                    </markerPoint>
                    <circleSimple :center="centroDoCirculo" :radius="valueSlider" />
                </mapaSimples>
            </div>
            <requiredFields />
            <div class="col-sm-12">
                <hr>
            </div>
        </div>
        <modalEagle id='modalTabela' title='Licenças/Certificações'>
            <slot slot="modalBody">
                <b-overlay :show='loadingModal'>
                    <div class="col-sm-12 row nopadding row">
                        <div class="col-sm-5 nopadding row">
                            <selectAll @changeSelect="changeSelectEmpresaModal" :labels="[
                                { indexDFH: 'EPM', description: 'Empresa*' }
                            ]" :optionsArray='optEmpresaModal' nameForRadio='descricaoCertificacao'
                                :isMultiple='false'>
                            </selectAll>
                        </div>
                        <div class="col-sm-5 nopadding">
                            <inputSimple :value='valueDescricaoModal' ref='descricaoModal'
                                @changeInput='changeInputDescricao' name='descricaoCertificacao' label='Descrição*'>
                            </inputSimple>
                        </div>
                        <div class="col-sm-2 nopadding">
                            <simpleButton :disabled='desativarBotaoSalvarModal' extraClass='botaoTelaCNH' text='Salvar'
                                type='blue' :icon='mdiCheckBold' event='click' @click="salvarLicencaModal">
                            </simpleButton>
                        </div>
                        <div class="col-sm-12" v-show="alertaModal">
                            <alertSimple :isLoading='false' :icon='mdiAlert' type='orange' message='
                                Você já possui licenças ou certificações 
                                cadastradas com os mesmos parâmetros
                            ' />
                        </div>
                    </div>
                    <div class="margi-top-30px" id="diferente">
                        <tableListagem @excluirCadastro="excluirCadastroLicencas" titleButton='Deletar'
                            :titles='titleTableModal' :data='dataTableModal'>
                        </tableListagem>
                    </div>
                </b-overlay>
            </slot>
            <slot slot="modalFooter">
                <simpleButton type="red" text="Fechar" :icon="mdiCloseThick" event="click"
                    @click="clickBotaoFecharModal" />
            </slot>
        </modalEagle>
    </panelEagle>
</template>
<script lang="js">
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue';
import {
    mdiAccountSupervisor,
    mdiCheckBold,
    mdiCloseThick,
    mdiAlert,
    mdiPlus
} from '@mdi/js'
import PortalVue from 'portal-vue'
import { conectionError } from '@/Services/Helpers/swellHeper'

Vue.use(PortalVue)

export default Vue.extend({
    components: {
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        tabsEagle: require('@/components/Atom/Tabs/TabsEagle').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        modalEagle: require('@/components/Atom/Modal/ModalEagle').default,
        mapaSimples: require('@/components/Atom/Mapa/MapaSimples').default,
        markerPoint: require('@/components/Atom/Mapa/MarkerPoint').default,
        popupMarker: require('@/components/Atom/Mapa/PopupMarker').default,
        inputSearch: require('@/components/Atom/Inputs/InputSearch').default,
        alertSimple: require('@/components/Atom/Alerts/AlertSimple').default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        circleSimple: require('@/components/Atom/Mapa/CircleSimple').default,
        basicSlider: require('@/components/Atom/Buttons/BasicSlider').default,
        tableListagem: require('@/components/Atom/Table/TableListagem').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        inputSingleDay: require('@/components/Atom/Datas/InputSingleDay').default,
        requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
        buttonsFilters: require('@/components/Atom/Buttons/ButtonsFilters').default,
        basicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
    },

    validations: {
        valueEmpresaDadosPessoais: { required },
        valueInputNome: { required },
        valueInputCPF: { required },
        valueEmail: { required }
    },

    data() {
        return {
            mdiPlus: mdiPlus,
            mdiAlert: mdiAlert,
            mdiCloseThick: mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            mdiAccountSupervisor: mdiAccountSupervisor,
            modelTab: 0,
            mostraMapa: true,
            tituloCartaoMotorista: '',
            tituloCartaoAjudante: '',
            cartaoMotorista: true,
            cartaoAjudante: true,
            // valores primeira tab
            selectedPontuacao: [],
            selectedEmpresaMultipla: [],
            selectedJornadaTrabalho: [],
            selectedGrupoColaboradores: [],
            selectedEmpresaDadosPessoais: [],
            valueBotaoFiltroStatus: [],         // 1
            valueBotaoFiltroTipo: [],           // 2
            valuePontuacao: [],                 // 3
            valueEmpresaDadosPessoais: [],      // 4
            valueInputNome: '',                 // 5
            valueInputCPF: '',                  // 6
            valueInputRG: '',                   // 7
            valueInputTelefone: '',             // 8
            valueInputDataDadosPessoais: '',    // 9
            valueInputNDeCartao: '',            // 10
            valueInputCartaoAjudante: '',       // 11
            valueInputCracha: '',               // 12
            valueEmail: '',                     // 13
            checkboxReceberNotificacao: false,  // 14
            valueJornadaDeTrabalho: [],         // 15
            valueInputPIS: '',                  // 16
            valueInputEsocial: '',
            valueGrupoColaboradores: [],        // 17
            valueEmpresaMultipla: [],           // 18
            // valores segunda tab              
            valueInputSearchMapa: '',           // 19
            valueLongitude: '',                 // 20
            valueLatitude: '',                  // 21
            valueSlider: 50,
            // valores terceira tab             
            selectedTipoCNH: [],
            valueInputCNH: '',                  // 22
            valueTipoLicenca: '',               // 23
            valueDataValidadeNumeroCNH: '',     // 24
            valueDataValidadeTabela: [],        // 25
            dataTable: [],                      // 26
            // valores quarta tab               
            valueCodgioInterfoc: null,           // 27

            idUsuario: '',
            valueDescricaoModal: '',
            valueEmpresaModal: [],
            valueLicenca: [],
            cpfInvalido: false,
            optPontuacao: [],
            desabilitaCartaoAjudante: 'disabled',
            alertaLicencaRepetida: false,
            desativarBotaoSalvarModal: true,
            centroDoCirculo: [0, 0],
            latitude: 0,
            longitude: 0,
            optEmpresaModal: new EmpresasService().Get(),
            titlesTabela: [
                {
                    number: 'descricao',
                    name: 'Descrição'
                },
                {
                    number: 'validade',
                    name: 'Validade'
                },
            ],
            titleTableModal: [
                {
                    number: 'descricao',
                    name: 'Descrição'
                },
                {
                    number: 'empresa',
                    name: 'Empresa'
                },
            ],
            optTipoCarteiraLicenca: [
                { value: "A", description: "A" },
                { value: "AB", description: "AB" },
                { value: "AC", description: "AC" },
                { value: "AD", description: "AD" },
                { value: "AE", description: "AE" },
                { value: "B", description: "B" },
                { value: "C", description: "C" },
                { value: "D", description: "D" },
                { value: "E", description: "E" },
            ],
            enderecosMapa: [],
            optTipoLicencaECertificacao: [],
            alertaModal: false,
            loadingModal: false,
            checkboxMultiEmpresa: false,
            mostraSeletorLocalMapa: true,
            optGrupoDeMotorista: [],
            optJornadaTrab: [],
            titleCPF: '',
            loadingPanelEagle: false,
            loadingEndereco: false,
            dataTableModal: [],
            optEmpresas: new EmpresasService().Get(),
            arrayButtonsTipoTabDados: [
                {
                    value: 'M',
                    width: '32.0%',
                    isSelected: true,
                    extraClass: '',
                    title: 'Motorista',
                    text: 'Motorista',
                    icon: ''
                },
                {
                    value: 'A',
                    width: '32.0%',
                    isSelected: false,
                    extraClass: '',
                    title: 'Ajudante',
                    text: 'Ajudante',
                    icon: ''
                },
                {
                    value: 'AD',
                    width: '32.0%',
                    isSelected: false,
                    extraClass: '',
                    title: 'Administrativo',
                    text: 'Administrativo',
                    icon: ''
                },
            ],
            arrayButtonsStatusTabDados: [
                {
                    value: 'A',
                    width: '47.70%',
                    isSelected: true,
                    extraClass: '',
                    title: 'Ativos',
                    text: 'Ativos',
                    icon: ''
                },
                {
                    value: 'I',
                    width: '47.70%',
                    isSelected: false,
                    extraClass: '',
                    title: 'Inativos',
                    text: 'Inativos',
                    icon: ''
                },
            ],
            tabs: [
                {
                    title: 'Dados pessoais',
                    value: 'dados'
                },
                {
                    title: 'Endereço',
                    value: 'endereco'
                },
                {
                    title: 'Licenças',
                    value: 'licencas'
                },
                {
                    title: 'Integração',
                    value: 'integracao'
                },
            ],
            // tabIndex:0,
        }
    },

    methods: {
        testeClickTab(value) {
            if (value == 1) {
                this.$refs.mapaEndereco.validateSize()
                this.mostraMapa = true
            } else {
                this.mostraMapa = false
            }
        },

        changeInputDataValidadeNumeroCNH(value) {
            this.valueDataValidadeNumeroCNH = value
        },

        changeSelectTipoLicenca(value) {
            this.valueTipoLicenca = value
        },

        changeInputCNH(value) {
            this.valueInputCNH = value
        },

        changeInputPIS(value) {
            this.valueInputPIS = value
        },

        changeInputEsocial(value) {
            this.valueInputEsocial = value;
        },

        /**
        * @description Método para mostrar o toast na tela
        * @param {string} msg   - mensagem que vai aparecer no corpo do toast
        * @param {string} type  - qual o tipo do toast
        * @param {string} title - título para inserir no cabeçalho
        * @author Vitor Hugo 🐨
        */
        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 3500,
                variant: type,
                title: title
            });
        },

        changeEmpresaMultipla(value) {
            this.valueEmpresaMultipla = value
        },

        changeSelectGrupoColaboradores(value) {
            this.valueGrupoColaboradores = value
        },

        changeSelectJornadaDeTrabalho(value) {
            if (value[0] == null) {
                this.valueJornadaDeTrabalho = []
            } else {
                this.valueJornadaDeTrabalho = value
            }
        },

        changeInputCracha(value) {
            this.valueInputCracha = value
        },

        changeInputNDeCartao(value) {
            this.valueInputNDeCartao = value
        },

        changeInputNDeCartaoAjudante(value) {
            this.valueInputCartaoAjudante = value
        },

        changeInputDataDadosPessoais(value) {
            this.valueInputDataDadosPessoais = value
        },

        changeInputTelefone(value) {
            this.valueInputTelefone = value
        },

        changeInputNome(value) {
            this.valueInputNome = value
        },

        changeInputRG(value) {
            this.valueInputRG = value
        },

        changeSelectPontuacao(value) {
            this.valuePontuacao = value
        },

        changeSelectEmpresaDadosPessoais(value) {
            this.$refs.refselectAllLicenca.clearAll()
            this.$refs.refSelectPontuacao.clearAll()
            this.$refs.selectJornadaTrabalho.clearAll()
            this.$refs.selectGrupoMotorista.clearAll()
            this.valueEmpresaDadosPessoais = value
            if (value.length > 0) {
                this.buscaLicencaECertificacao()
                this.buscaPontuacao()
                this.buscaJornadaDeTrabalho()
                this.buscaGrupoColaboradores()
            }
        },

        async buscaGrupoColaboradores() {
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/busca/colaboradores',
                    { 'dados': this.valueEmpresaDadosPessoais }
                )
            this.optGrupoDeMotorista = dados.data
        },

        async buscaJornadaDeTrabalho() {
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/busca/jornada',
                    { 'dados': this.valueEmpresaDadosPessoais }
                )
            this.optJornadaTrab = dados.data
        },

        async buscaPontuacao() {
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/busca/pontuacao',
                    { 'dados': this.valueEmpresaDadosPessoais }
                )
            this.optPontuacao = dados.data
        },

        clickBotaoFecharModal() {
            this.$refs.refselectAllLicenca.clearAll()
            this.valueDescricaoModal = ''
            this.dataTableModal = []
            this.$bvModal.hide('modalTabela')
        },

        async buscaLicencaECertificacao() {
            this.optTipoLicencaECertificacao = []
            this.$refs.refselectAllLicenca.$data.selectedItens = ''
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/busca/licenca',
                    { 'empresa': this.valueEmpresaDadosPessoais }
                )
            this.optTipoLicencaECertificacao = dados.data
        },

        async buscaLicencaECertificacaoModal() {
            this.optTipoLicencaECertificacao = []
            this.$refs.refselectAllLicenca.$data.selectedItens = ''
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/busca/licenca',
                    { 'empresa': this.valueEmpresaModal }
                )
            this.optTipoLicencaECertificacao = dados.data
        },

        async dragEnd(value) {
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/endereco/proximo',
                    { 'local': [this.valueLatitude, this.valueLongitude] }
                )
            this.valueInputSearchMapa = dados.data
        },

        async excluirCadastroLicencas(value) {
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/excluir/licenca',
                    { 'id': value }
                )
            this.changeSelectEmpresaModal(this.valueEmpresaModal)
            this.excluirCadastroTable(value)
            this.buscaLicencaECertificacaoModal()
        },

        changeInputDescricao(value) {
            this.valueDescricaoModal = value
            this.ativaSalvarModal()
        },

        ativaSalvarModal() {
            if (
                this.valueEmpresaModal.length > 0 &&
                this.valueDescricaoModal.trim().length > 0
            ) {
                this.desativarBotaoSalvarModal = false
            } else {
                this.desativarBotaoSalvarModal = true
            }
        },

        async salvarLicencaModal() {
            this.loadingModal = true
            this.alertaModal = false
            for (const index in this.dataTableModal) {
                if (this.dataTableModal[index].descricao == this.valueDescricaoModal) {
                    this.alertaModal = true
                    break
                } else {
                    this.alertaModal = false
                }
            }
            if (!this.alertaModal) {
                var dados = await new HttpRequest()
                    .Post('/administrativo/cadastros/colaboradores/salvar/licenca',
                        { 'dados': [this.valueDescricaoModal, this.valueEmpresaModal] }
                    )
                this.loadingModal = false
                this.valueDescricaoModal = ''
                this.changeSelectEmpresaModal(this.valueEmpresaModal)
                this.buscaLicencaECertificacaoModal()
            } else {
                this.valueDescricaoModal = ''
                this.loadingModal = false
                setTimeout(() => {
                    this.alertaModal = false
                }, 5000);
            }
        },

        async changeSelectEmpresaModal(value) {
            this.valueEmpresaModal = value
            this.loadingModal = true
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/licenca',
                    { 'empresa': value }
                )
            this.dataTableModal = dados.data
            this.loadingModal = false
            this.ativaSalvarModal()
        },

        clickBotaoAbrirModal() {
            this.$refs.refselectAllLicenca.clearAll()
            this.valueDescricaoModal = ''
            this.dataTableModal = []
            this.$bvModal.show('modalTabela')
        },

        async clickButtonSearchMapa(value) {
            this.loadingEndereco = true
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/colaboradores/endereco',
                    { 'local': value }
                )
            this.enderecosMapa = dados.data.enderecos
            this.mostraSeletorLocalMapa = true
            this.loadingEndereco = false
        },

        changeSlider(value) {
            this.valueSlider = value
        },

        dragMarker(value) {
            this.valueLatitude = value['latlng'].lat
            this.valueLongitude = value['latlng'].lng
            this.centroDoCirculo = [
                Number(value['latlng'].lat),
                Number(value['latlng'].lng)
            ]
        },

        excluirCadastroTable(value) {
            for (const index in this.dataTable) {
                if (this.dataTable[index].codigo == value) {
                    this.dataTable.splice(index, 1)
                    break
                }
            }
        },

        clickBotaoAdicionar() {
            if (this.valueLicenca.length > 0) {
                if (this.$refs.refselectAllLicenca.$data.selectedItens.description != null) {
                    var desc = this.$refs.refselectAllLicenca.$data.selectedItens.description
                    var validade = this.valueDataValidadeTabela
                    var index = 0
                    var controle = true
                    var adicionar = {
                        'descricao': desc,
                        'validade': validade,
                        'codigo': this.valueLicenca[0]
                    }
                    for (const dados in this.dataTable) {
                        if (this.dataTable[dados].codigo == adicionar.codigo) {
                            controle = false
                            break
                        }
                    }
                    if (controle) {
                        this.alertaLicencaRepetida = false
                        this.dataTable.push(
                            {
                                'descricao': desc,
                                'validade': validade,
                                'codigo': this.valueLicenca[0]
                            }
                        )
                    } else {
                        this.alertaLicencaRepetida = true
                        setTimeout(() => {
                            this.alertaLicencaRepetida = false
                        }, 5000);
                    }
                }
            }
        },

        changeInputCPF(value) {
            this.valueInputCPF = ''
            if (value.length == 14) {
                var str = value
                var res = str.replace('.', '');
                var ress = res.replace('-', '');
                var CpfFinal = ress.replace('.', '');
                var a = '11111111111'
                var b = '22222222222'
                var c = '33333333333'
                var d = '44444444444'
                var e = '55555555555'
                var f = '66666666666'
                var g = '77777777777'
                var h = '88888888888'
                var i = '99999999999'
                var j = '00000000000'
                var k = '12345678900'
                if (CpfFinal != a &&
                    CpfFinal != b &&
                    CpfFinal != c &&
                    CpfFinal != d &&
                    CpfFinal != e &&
                    CpfFinal != f &&
                    CpfFinal != g &&
                    CpfFinal != h &&
                    CpfFinal != i &&
                    CpfFinal != j &&
                    CpfFinal != k) {
                    this.testaCpf(CpfFinal, value)
                } else {
                    this.cpfInvalido = true
                    this.addClassCpf()
                }
            }
        },

        testaCpf(strCPF, value) {
            var cpf = strCPF
            var i = 0
            var multiplica = 10
            var valorFinal = 0
            while (i < 9) {
                var conta = cpf[i]
                var soma = conta * multiplica
                valorFinal = valorFinal + soma
                multiplica = multiplica - 1
                i = i + 1
            }
            var divisao = valorFinal % 11
            divisao = 11 - divisao
            if (divisao == cpf[9] || cpf[9] == 0) {
                var indice = 0
                var multiplicador = 11
                var valorFinalSegundo = 0
                while (indice < 10) {
                    var contaSegunda = cpf[indice]
                    var somaSegunda = contaSegunda * multiplicador
                    valorFinalSegundo = valorFinalSegundo + somaSegunda
                    multiplicador = multiplicador - 1
                    indice = indice + 1
                }
                var divisaoSegunda = valorFinalSegundo % 11
                divisaoSegunda = 11 - divisaoSegunda
                if (divisaoSegunda == cpf[10] || cpf[10] == 0) {
                    this.cpfInvalido = false
                    this.valueInputCPF = value
                    this.removeClassCpf()
                } else {
                    this.cpfInvalido = true
                    this.addClassCpf()
                }
            } else {
                this.cpfInvalido = true
                this.addClassCpf()
            }
        },

        addClassCpf() {
            this.titleCPF = 'CPF inválido'
            var elemento = document.getElementById('cpf');
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('cpf');
            if (getIndex === -1) {
                classes.push('cpf');
                elemento.className = classes.join(' ');
            }
        },

        removeClassCpf() {
            this.titleCPF = ' '
            var elemento = document.getElementById('cpf');
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('cpf');

            if (getIndex > -1) {
                classes.splice(getIndex, 1);
            }
            elemento.className = classes.join(' ');
        },

        changeSelectLicencasECertificacao(value) {
            this.valueLicenca = value
        },

        changeInputLicenca(value) {
            this.valueLicenca = value
        },

        clickListaEnderecoMapa(value) {
            this.$refs.mapaEndereco.flyTo(
                [value.centro[1], value.centro[0]]
            )
            this.latitude = value.centro[1]
            this.longitude = value.centro[0]
            this.valueInputSearchMapa = value.endereco
            this.valueLatitude = value.centro[1]
            this.valueLongitude = value.centro[0]
            this.centroDoCirculo = [
                Number(value.centro[1]),
                Number(value.centro[0])
            ]
            this.mostraSeletorLocalMapa = false
        },

        async salvarCadastro(value) {
            this.loadingPanelEagle = true
            var cartao = this.valueInputNDeCartao.length >= 5
                || this.valueInputNDeCartao.length == 0
            var cartaoAjudante = this.valueInputCartaoAjudante.length >= 5
                || this.valueInputCartaoAjudante.length == 0
            if ((cartao && cartaoAjudante) == true) {
                if (this.valueInputEsocial != null && this.valueInputEsocial.length > 30) {
                    let html = '<p style="color: red !important;">O cadastro da matrícula no eSocial tem um limite máximo de 30 caracteres!</p>';

                    conectionError(html, 'warning', 'Atenção')
                    this.loadingPanelEagle = false;
                    return;
                }

                var valores = [
                    {   // valores primeira tab
                        'statusDados': this.valueBotaoFiltroStatus,                  // 1
                        'tipoDados': this.valueBotaoFiltroTipo,                      // 2
                        'pontuacao': this.valuePontuacao,                            // 3
                        'empresaDados': this.valueEmpresaDadosPessoais,              // 4
                        'nomeDados': this.valueInputNome,                            // 5
                        'CPFDados': this.valueInputCPF,                              // 6
                        'RGDados': this.valueInputRG,                                // 7
                        'telefoneDados': this.valueInputTelefone,                    // 8
                        'dataDados': this.valueInputDataDadosPessoais,               // 9
                        'numeroCartaoDados': this.valueInputNDeCartao,               // 10
                        'numeroCartaoAjudanteDados': this.valueInputCartaoAjudante,  // 11
                        'crachaDados': this.valueInputCracha,                        // 12
                        'emailsDados': this.valueEmail,                              // 13
                        'receberNotificacaoDados': this.checkboxReceberNotificacao,  // 14
                        'jornadaDados': this.valueJornadaDeTrabalho,                 // 15
                        'PISDados': this.valueInputPIS,                              // 16
                        'grupoColaboradoresDados': this.valueGrupoColaboradores,     // 17
                        'empresaMultiplaDados': this.valueEmpresaMultipla,            // 18
                        'esocial': this.valueInputEsocial
                    },
                    {   // valores segunda tab
                        'raioEndereco': this.valueSlider,
                        'enderecoEncereco': this.valueInputSearchMapa,               // 19
                        'longiduteEndereco': this.valueLongitude,                    // 20
                        'latitudeEndereco': this.valueLatitude,                      // 21
                    },
                    {   // valores terceira tab
                        'CNHLicenca': this.valueInputCNH,                            // 22
                        'tipoLicenca': this.valueTipoLicenca,                        // 23
                        'validadeCNHLIcenca': this.valueDataValidadeNumeroCNH,       // 24
                        // 'validadeTabelaCNHLicenca':this.valueDataValidadeTabela, // 25
                        'tabelaLicenca': this.dataTable,                             // 26 
                    },
                    {   // valores quarta tab
                        "codgioInterfoc": this.valueCodgioInterfoc                   // 27
                    },
                    {   // dados usuario
                        'idUsuario': this.idUsuario
                    }
                ]

                var dados = await new HttpRequest()
                    .Post('/administrativo/cadastros/colaboradores/salva/dados',
                        { 'dados': valores }
                    )

                if (dados.code == 201) {
                    this.$router.push({ name: 'listarMotoristas' })
                } else if (dados.code == 200) {
                    var title = 'Este CPF já esta cadastrado';
                    var html = '';

                    if (dados.data.interfoc) {
                        html = '<p style="color: red !important;">Informe o código INTERFOC!</p>';
                        title = 'Não foi possível concluir a operação.';
                    }

                    if (dados.data.matriculaEsocial) {
                        html = '<p style="color: red !important;">Matrículo Esocial já cadastrada!</p>';
                        title = 'Não foi possível concluir a operação.';
                    }

                    conectionError(html, 'warning', title)
                } else {
                    conectionError()
                }
            } else {
                if (!cartao) {
                    this.avisaCartao()
                } else {
                    this.tituloCartaoMotorista = ''
                }
                if (!cartaoAjudante) {
                    this.avisaCartaoAjudante()
                } else {
                    this.tituloCartaoAjudante = ''
                }
            }
            this.loadingPanelEagle = false
        },

        cancelarCadastro() {
            this.$router.push({ name: 'listarMotoristas' })
        },

        addClassBordaVermelha(value) {
            var elemento = document.getElementById(value);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('borda-vermelha');
            if (getIndex === -1) {
                classes.push('borda-vermelha');
                elemento.className = classes.join(' ');
            }
        },

        removeClassBordaVermelha(value) {
            var elemento = document.getElementById(value);
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('borda-vermelha');
            if (getIndex > -1) {
                classes.splice(getIndex, 1);
            }
            elemento.className = classes.join(' ');
        },

        avisaCartao() {
            this.addClassBordaVermelha('cartaoMotorista')
            this.tituloCartaoMotorista = 'Campo inválido'
            setTimeout(() => {
                this.tituloCartaoMotorista = ' '
                this.removeClassBordaVermelha('cartaoMotorista')
            }, 5000);
        },

        avisaCartaoAjudante() {
            this.addClassBordaVermelha('cartaoAjudante')
            this.tituloCartaoAjudante = 'Campo inválido'
            setTimeout(() => {
                this.tituloCartaoAjudante = ' '
                this.removeClassBordaVermelha('cartaoAjudante')
            }, 5000);
        },

        changeInputDataValidadeTabela(value) {
            this.valueDataValidadeTabela = value
        },

        changeInputEmail(value) {
            var dados = value.split('@', value)
            if (dados.length == 0) {
                this.valueEmail = value
            } else {
                this.valueEmail = ''
                this.checkboxReceberNotificacao = false
            }
        },

        buttonsFiltersChangeStatus(value) {
            this.valueBotaoFiltroStatus = value
            if (value[0] == 'A') {
                this.arrayButtonsStatusTabDados[0].icon = mdiCheckBold
                this.arrayButtonsStatusTabDados[1].icon = ''
                this.arrayButtonsStatusTabDados[0].isSelected = true
                this.arrayButtonsStatusTabDados[1].isSelected = false
            } else {
                this.arrayButtonsStatusTabDados[0].icon = ''
                this.arrayButtonsStatusTabDados[1].icon = mdiCheckBold
                this.arrayButtonsStatusTabDados[0].isSelected = false
                this.arrayButtonsStatusTabDados[1].isSelected = true
            }
        },

        /**
         * Faz a validação de quando e trocado btn de tipo colaborador
         * e atribui a regra de
         * Selecionando AD, permite apenas o valor AD
         * Selecionado A ou M, permite os valores existirem juntos 
         * caso receba um valor de ["AD"] e na próxima vez
         * ele receba um valor ["X", "AD"], vai assumir que o valor
         * não pode mais ser ["AD"] e sim ["X"]
         * @listens buttonsFiltersChange
         * @param {Array|Undefined} value
         * @example ["M", "A", "AD"]
         * @author Lucas Eduardo
         */
        buttonsFiltersChangeTipo(value) {
            this.desselecionaFiltro('MAAD')
            if (!value) {
                this.selecionaFiltro(this.valueBotaoFiltroTipo)
                this.atribuiValorFiltro(this.valueBotaoFiltroTipo)
                return
            }
            const AD = value.findIndex(v => v === 'AD')
            const A = value.findIndex(v => v === 'A')
            const M = value.findIndex(v => v === 'M')
            if (AD != -1) {
                if (this.valueBotaoFiltroTipo[0] != 'AD') {
                    this.selecionaFiltro(['AD'])
                    this.atribuiValorFiltro(['AD'])
                } else {
                    this.selecionaFiltro([value[0]])
                    this.atribuiValorFiltro([value[0]])
                }
                return
            }
            if (A != -1 || M != -1) {
                this.selecionaFiltro(value)
            }
            this.atribuiValorFiltro(value)
            this.atribuiValorFiltro(value)
        },

        /**
         * atribui valores para os filtros de acordo com o que tu passar
         * so verifcia as letras AD, M, A
         * @param {String|Array|Undefined} dados
         * @example ["M", "AD"] | MAD
         * @author Lucas Eduardo
         */
        atribuiValorFiltro(value) {
            if (!value) return
            if (value.find(v => v === 'AD')) {
                this.valueBotaoFiltroTipo = ['AD']
                return
            }
            var dado = []
            if (value.find(v => v === 'M')) {
                dado.push('M')
            }
            if (value.find(v => v === 'A')) {
                dado.push('A')
            }
            this.valueBotaoFiltroTipo = dado
        },

        /**
         * Desseleciona os filtros de acordo com o que tu passar
         * so verifcia as letras AD, M, A
         * @param {String|Array|Undefined} dados
         * @example ["M", "AD"] | MAD
         * @author Lucas Eduardo
         */
        desselecionaFiltro(dados) {
            if (!dados) return
            if (dados.includes('AD')) {
                this.arrayButtonsTipoTabDados[2].icon = ''
                this.arrayButtonsTipoTabDados[2].isSelected = false
            } if (dados.includes('M')) {
                this.arrayButtonsTipoTabDados[0].icon = ''
                this.arrayButtonsTipoTabDados[0].isSelected = false
            } if (dados.includes('A')) {
                this.arrayButtonsTipoTabDados[1].icon = ''
                this.arrayButtonsTipoTabDados[1].isSelected = false
            }
        },

        /**
         * seleciona os filtros de acordo com o que tu passar
         * so verifcia as letras AD, M, A
         * @param {String|Array|Undefined} dados
         * @example ["M", "AD"] | MAD
         * @author Lucas Eduardo
         */
        selecionaFiltro(dados) {
            if (!dados) return
            if (dados.includes('AD')) {
                this.arrayButtonsTipoTabDados[2].icon = mdiCheckBold
                this.arrayButtonsTipoTabDados[2].isSelected = true
                this.desselecionaFiltro('MA')
            } else {
                if (dados.includes('A')) {
                    this.arrayButtonsTipoTabDados[1].icon = mdiCheckBold
                    this.arrayButtonsTipoTabDados[1].isSelected = true
                } if (dados.includes('M')) {
                    this.arrayButtonsTipoTabDados[0].icon = mdiCheckBold
                    this.arrayButtonsTipoTabDados[0].isSelected = true
                }
            }
        },

        arrumaBotaoFiltro(value) {
            if (value == 'A') {
                this.buttonsFiltersChangeTipo(['A'])
            }
            if (value == 'AD') {
                this.buttonsFiltersChangeTipo(['AD'])
            }
            if (value == 'M') {
                this.buttonsFiltersChangeTipo(['M'])
            }
            if (value == 'MA') {
                this.buttonsFiltersChangeTipo(['M', 'A'])
            }
        },

        async arrumaValoresNosCampos(value) {
            if (value.mtlatitude != null) {
                this.$refs.mapaEndereco.flyTo(
                    [value.consultaMotorista[0].mtlatitude, value.consultaMotorista[0].mtlongitude]
                )
            }
            // valores primeira tab
            this.buttonsFiltersChangeStatus([value.consultaMotorista[0].mtstatus])
            this.arrumaBotaoFiltro(value.consultaMotorista[0].mtperfil)
            this.selectedEmpresaDadosPessoais = [{                                           // 4
                value: value.consultaMotorista[0].mtcliente,                                 // 4
                description: value.consultaMotorista[0].clfantasia                           // 4
            }]
            this.valueInputNome = value.consultaMotorista[0].mtnome                          // 5 
            this.changeInputCPF(value.consultaMotorista[0].mtcpf)                            // 6
            this.valueInputRG = value.consultaMotorista[0].mtrg                             // 7
            this.valueInputTelefone = value.consultaMotorista[0].mttelefone                  // 8
            this.valueInputDataDadosPessoais = value.consultaMotorista[0].mtdatanasc         // 9 
            this.valueInputEsocial = value.consultaMotorista[0].mtmatesocial

            this.valueInputNDeCartao = value.consultaMotorista[0].mtcartao != null ?
                value.consultaMotorista[0].mtcartao : ''
            this.valueInputCartaoAjudante = value.consultaMotorista[0].mtcartaoajudante != null ?
                value.consultaMotorista[0].mtcartaoajudante : ''
            this.valueInputCracha = value.consultaMotorista[0].mtcracha != null ?
                value.consultaMotorista[0].mtcracha : '' // 12
            if (value.consultaMotorista[0].mtemail != null) {
                this.valueEmail = value.consultaMotorista[0].mtemail                         // 13
                this.checkboxReceberNotificacao =                                            // 14
                    value.consultaMotorista[0].mtrecebenotificacao == null ?
                        false : value.consultaMotorista[0].mtrecebenotificacao
            }

            if (value.consultaMotorista[0].mtjornada != null) {
                this.selectedJornadaTrabalho = [{
                    value: value.consultaMotorista[0].mtjornada,
                    description: value.consultaMotorista[0].jtdescricao
                }]                                                                               // 15
            }

            this.valueInputPIS = value.consultaMotorista[0].mtpis                            // 16
            this.selectedGrupoColaboradores = [{
                value: value.consultaMotorista[0].mtgrupo,
                description: value.consultaMotorista[0].gmdescricao
            }]
            if (value.consultaMotoristaEmpresa.length > 0) {
                this.checkboxMultiEmpresa = true
                this.selectedEmpresaMultipla = value.consultaMotoristaEmpresa
            }
            setTimeout(() => { // tem algum jeito melhor? avise o lucas. O campo fica limpo nesse campo pq é ativado junto com o input de empresa, e la tem um bagui que limpa o campo
                this.selectedPontuacao = [{
                    value: value.consultaMotorista[0].mtpontuacao,
                    description: value.consultaMotorista[0].ppadescricao                         // 3
                }]
            }, 100);
            this.valueSlider = value.consultaMotorista[0].mtraio                              // 19
            this.valueInputSearchMapa = value.consultaMotorista[0].mtendereco                 // 20
            this.centroDoCirculo = [
                value.consultaMotorista[0].mtlatitude != null
                    ? value.consultaMotorista[0].mtlatitude : 0,
                value.consultaMotorista[0].mtlongitude != null
                    ? value.consultaMotorista[0].mtlongitude : 0
            ]
            this.latitude = value.consultaMotorista[0].mtlatitude != null ?
                value.consultaMotorista[0].mtlatitude : 0
            this.longitude = value.consultaMotorista[0].mtlongitude != null ?
                value.consultaMotorista[0].mtlongitude : 0

            this.valueLongitude = value.consultaMotorista[0].mtlongitude != null ?
                value.consultaMotorista[0].mtlongitude : ''
            this.valueLatitude = value.consultaMotorista[0].mtlatitude != null ?
                value.consultaMotorista[0].mtlatitude : ''
            this.valueInputCNH = value.consultaMotorista[0].mtcnhnumero                       // 23
            this.valueDataValidadeNumeroCNH = value.consultaMotorista[0].mtcnhvalidade        // 24
            this.dataTable = value.consultaMotoristaLIcenca                                   // 25
            this.selectedTipoCNH = [{
                value: value.consultaMotorista[0].mtcnh,
                description: value.consultaMotorista[0].mtcnh
            }]
            this.valueTipoLicenca = [value.consultaMotorista[0].mtcnh]
            this.valueCodgioInterfoc = value.consultaMotorista[0].mtcodigointerfoc
        },
    },

    async mounted() {
        this.$refs.mapaEndereco.validateSize()
        this.loadingPanelEagle = true
        this.$nextTick(() => {
            this.mostraMapa = false
        })
        if (this.$route.params.id !== undefined) {
            this.idUsuario = this.$route.params.id
            var url = '/administrativo/cadastros/colaboradores/busca/dados'
            var consulta = await new HttpRequest()
                .Post(url, { 'id': this.idUsuario })
            this.arrumaValoresNosCampos(consulta.data)
        }
        this.loadingPanelEagle = false
    },
})
</script>

<style lang="scss">
.borda-vermelha {
    border: red 1px solid !important;
}

.margin-bot-grande {
    margin-bottom: 15px;
}

.margin-bot {
    margin-bottom: -35px;
}

.cpf {
    border: 1px solid red !important;
}

.position {
    position: relative;
}

.padd-seletor-rua {
    border: 1px solid #ffffff;
    margin-right: 5px !important;
    background-color: #ffffff;
    z-index: 1000 !important;
    top: -12px !important;
    left: 0px !important;
    padding-right: 15px !important;
}

.position-abs {
    z-index: 990 !important;
    position: absolute;
}

.div-local-tamanho {
    top: -45px;
    padding-left: 30px !important;
    float: left;
    position: absolute;
    max-height: 200px;
    z-index: 1;
    overflow-x: auto;
}

.margin-none {
    margin-bottom: -40px;
    // margin: none;
}

div#diferente>div>div>div>div>div>div>table>tbody>tr>td>div>div>button.bt-green {
    display: none !important;
}

.padd-lef-0 {
    padding-left: 0px;
}

.padd-rig-0 {
    padding-right: 0px;
}

.texto-pequeno {
    font-size: 12px;
}

.pad-10px {
    text-align: left;
    padding-left: 35px;
}

.margi-top-10px {
    margin-top: 10px;
}

.margi-top-30px {
    margin-top: 30px;
}

.botaoTelaCNH {
    height: 50%;
    margin-top: 30px;
}
</style>
