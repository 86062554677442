<template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="9" class="nopadding">
                        <span class="newbadge">
                            {{ dados.descricao }}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>Tipo do registro</td>
                    <td>Nome do PTRP (Programa de Tratamento de Registro de Ponto)</td>
                    <td>Versão do PTRP</td>
                    <td>Tipo de identificador do desenvolvedor</td>
                    <td>CNPJ</td>
                    <td>Razão social</td>
                    <td>E-mail do desenvolvedor do PTRP.</td>
                </tr>

                <template v-if="dados.tipo8 && dados.tipo8.length">
                    <tr v-for="(item, i) in dados.tipo8" :key="i + '_item'">
                        <td>{{ item.tipoReg }}</td>
                        <td>{{ item.nome }}</td>
                        <td>{{ item.versao }}</td>
                        <td>{{ item.tipoIdentificador }}</td>
                        <td>{{ item.cnpj }}</td>
                        <td>{{ item.razaoSocial }}</td>
                        <td>{{ item.email }}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr class="paddingTd">
                        <td colspan="9" style="padding:0!important">
                            <div class="tableDataEmpty">
                                <span class="textEmpty">Sem dados</span>
                            </div>
                        </td>
                    </tr>
                </template>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'registroTipo8',
    components: {
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props: {
        dados: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
    },
    methods: {
        mostrar(dados) {
            console.log(dados)
        }
    }
})
</script>

<style lang="scss"></style>