<template>
    <panelEagle id="monitoramentoLinhasView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiTelevisionGuide" titulo="Monitoramento linhas">
                    </tituloPage>
                </div>

                <div class="col-sm-6">
                    <buttonExpand>
                        <simpleButton width="120px" :icon="mdiRelativeScale" type="green" text="Iniciar linha"
                            title="Iniciar linha" typeButton="button" event="iniciarLinha"
                            @iniciarLinha="abrirModalIniciarLinha">
                        </simpleButton>

                        <simpleButton id="aplicarFiltros" width="120px" :icon="mdiPlus" type="orange"
                            text="Aplicar filtros" title="Aplicar filtros">
                        </simpleButton>

                        <simpleButton id="popoverInfo" width="120px" :icon="mdiAlertCircleOutline" type="blue"
                            text="Legenda" title="Legenda">
                        </simpleButton>
                    </buttonExpand>
                </div>
            </div>

            <!-- Legnda -->
            <div>
                <b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-12 nopadding custom-break-line">
                            <subtitle :items="itemsSubtitleRota" title="Rota">
                            </subtitle>
                            <subtitle :items="itemsSubtitlePontos" title="Pontos">
                            </subtitle>
                        </div>
                    </div>
                </b-popover>
            </div>

            <!-- Filtros -->
            <div>
                <b-popover id="popoverFiltros" target="aplicarFiltros" triggers="hover click"
                    :show.sync="popoverFiltrosShow" placement="top" ref="popover" @hidden="onHidden">
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12 nopadding">
                            <!-- Input Data -->
                            <div class="mb-3">
                                <inputSingleDay @changeInput="changeData" name="dataCancelamentos" :isObrigatorio="true"
                                    :value="dataSelecionada">
                                </inputSingleDay>
                            </div>

                            <!-- Input Empresas -->
                            <div class="mb-3">
                                <selectAll @changeSelect="changeEmpresa" :isMultiple="true"
                                    nameForRadio="empresasCancelamento" :hasSelectAll="true"
                                    :labels="[{ indexDFH: 'EM', description: 'Empresas*' }]"
                                    :optionsArray="optionsEmpresas" :selected="popoverData.empresas" firstSelected="EM">
                                </selectAll>
                            </div>

                            <!-- Inputs Veículos | Grupos -->
                            <div class="mb-3">
                                <div class="col-sm-12 nopadding">
                                    <div class="d-flex justify-content-start" style="margin-top: -5px;">
                                        <!-- Radio de Veículos -->
                                        <div class="d-flex justify-content-start ml-5">
                                            <input style="cursor: pointer;" class="form-check-input" type="radio"
                                                name="veiculoGrupoTag" id="radioVeiculo" v-model="selectedRadio"
                                                :value="1" @change="selecionaTipo(1)">
                                            <label class="select-none" for="radioVeiculo"
                                                style="cursor: pointer">Veículos</label>
                                        </div>

                                        <!-- Radio de Grupos -->
                                        <div class="d-flex justify-content-start ml-5">
                                            <input style="cursor: pointer;" class="form-check-input" type="radio"
                                                name="veiculoGrupoTag" id="radioGrupo" v-model="selectedRadio"
                                                :value="2" @change="selecionaTipo(2)">
                                            <label class="select-none" for="radioGrupo"
                                                style="cursor: pointer">Grupos</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 row nopadding" style="margin-top: -2px;">
                                        <!-- Select Veículos -->
                                        <div v-if="tipoSelecionado == 1" class="col-sm-12 nopadding">
                                            <selectAll :selected="popoverData.veiculos" ref="srefSelectAllVeiculos"
                                                @changeSelect="changeSelectVeiculos" nameForRadio="veiculoOuGrupoModal"
                                                :isMultiple="true" :hasSelectAll="true" :optionsArray="veiculosDoBanco"
                                                :loading="loadingVeiculos" :disabled="loadingVeiculos" />
                                        </div>

                                        <!-- Select Grupo -->
                                        <div v-if="tipoSelecionado == 2" class="col-sm-12 nopadding">
                                            <selectAll :selected="popoverData.grupos" ref="srefSelectAllGruposVeiculos"
                                                @changeSelect="changeSelectGrupoVeiculos"
                                                nameForRadio="veiculoOuGrupoModal" :isMultiple="true"
                                                :hasSelectAll="true" :optionsArray="grupoVeiculoDoBanco"
                                                :loading="loadingGrupos" :disabled="loadingGrupos" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Input Status -->
                            <div class="mb-3">
                                <buttonsFilters @buttonsFiltersChange="changeStatus" :arrayButtons="arrayButtonsStatus"
                                    label="Status" :onlyOneSelected="false">
                                </buttonsFilters>
                            </div>

                            <!-- Input Turnos -->
                            <div class="mb-3">
                                <buttonsFilters @buttonsFiltersChange="changeTurno" :arrayButtons="arrayButtonsTurnos"
                                    label="Turno" :onlyOneSelected="false">
                                </buttonsFilters>
                            </div>

                            <!-- Botões -->
                            <div class="col-sm-12 d-flex justify-content-center align-items-center">
                                <!-- Filtrar -->
                                <div>
                                    <SimpleButton :disabled="$v.$invalid" text="Filtrar" type="blue" event="gerar"
                                        @gerar="getInformacoesLinhas" style="float:right;" />
                                </div>

                                <!-- Cancelar -->
                                <div>
                                    <SimpleButton text="Fechar" :icon="mdiCloseThick" type="red" event="gerar"
                                        @gerar="limparFiltros" style="float:right;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-popover>
            </div>

            <div class="col-sm-12">
                <hr>
            </div>

            <div class="divListagemLinhas col-sm-12">
                <statusInformation v-if="arrayDados.length == 0" typeBar="div" :statusBar="statusBar">
                </statusInformation>

                <monitoramentoLP ref="monitoramentoLP" @linhaCriada="linhasPrincipaisCriadas"
                    v-for="(rotaP, index) in arrayDados" :key="index" :arrayDados="rotaP">
                </monitoramentoLP>
            </div>

            <!-- Modal para iniciar a linha -->
            <modalEagle @cancelButton="fecharModalIniciarLinha" @confirmButton="iniciarLinha()" id="modalIniciarLinha"
                :title="nomeLinhaSelecionada <= 0 && linhaSelecionada <= 0 ? ('Iniciar linha') : (`Iniciar linha -  ${nomeLinhaSelecionada}`)"
                :tamanhoBtn="'110px'" hideHeaderClose textoConfirmar="Salvar">
                <template #modalBody>
                    <!-- Input Empresas -->
                    <div class="mb-3 col-sm-6 nopadding">
                        <selectAll @changeSelect="changeEmpresaSimples" :isMultiple="false"
                            nameForRadio="empresasCancelamento" :hasSelectAll="false"
                            :labels="[{ indexDFH: 'EMS', description: 'Empresa*' }]" :optionsArray="optionsEmpresas"
                            :selected="selectedEmpresaLinha" firstSelected="EMS">
                        </selectAll>
                    </div>

                    <div class="container nopadding">
                        <div class="row nopadding">
                            <!-- Input turno -->
                            <div class="col nopadding">
                                <selectAll @changeSelect="changeTurnoLinha" :isMultiple="false"
                                    nameForRadio="turnoSelecionado" :hasSelectAll="false"
                                    :labels="[{ indexDFH: 'TU', description: 'Selecione o turno:*' }]"
                                    :optionsArray="optionsTurnos" :selected="selectedTurno" firstSelected="TU">
                                </selectAll>
                            </div>

                            <!-- Input linha -->
                            <div class="col nopadding">
                                <selectAll @changeSelect="changeLinha" :isMultiple="false"
                                    nameForRadio="linhaSelecionada" :hasSelectAll="false"
                                    :labels="[{ indexDFH: 'LI', description: 'Selecione uma linha:*' }]"
                                    :optionsArray="optionsLinhas" :selected="selectedLinha" firstSelected="TU"
                                    :loading="loadingCarregandoLinhas" :disabled="optionsLinhas.length <= 0">
                                </selectAll>
                            </div>
                        </div>
                    </div>

                    <div class="container mt-4 nopadding">
                        <div class="row nopadding">
                            <!-- Input escala -->
                            <div class="col nopadding">
                                <selectAll @changeSelect="changeEscala" :isMultiple="false"
                                    nameForRadio="escalaSelecionada" :hasSelectAll="false"
                                    :labels="[{ indexDFH: 'ES', description: 'Selecione uma escala:*' }]"
                                    :optionsArray="optionsEscalas" :selected="selectedEscala" firstSelected="TU"
                                    :loading="loadingCarregandoEscalas" :disabled="optionsEscalas.length <= 0">
                                </selectAll>
                            </div>

                            <!-- Input veículo -->
                            <div class="col nopadding">
                                <selectAll @changeSelect="changeVeiculoLinha" :isMultiple="false"
                                    nameForRadio="veiculoSelecionado" :hasSelectAll="false"
                                    :labels="[{ indexDFH: 'VEL', description: 'Selecione um veículo:*' }]"
                                    :optionsArray="veiculosDoBanco" :selected="selectedVeiculo" firstSelected="TU"
                                    :loading="loadingCarregandoLinhas" :disabled="optionsLinhas.length <= 0">
                                </selectAll>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div>
                        <div class="styleBackgroundLinhaIniciada p-2 mb-4">
                            <p class="styleTitleLinhaIniciada row font-bold">Informações gerais da linha iniciada:</p>
                        </div>

                        <div class="container nopadding">
                            <div class="row nopadding">
                                <div class="col nopadding">
                                    <!-- Input data -->
                                    <inputSingleDay @changeInput="changeDataLinha" name="dataIniciarLinha"
                                        :isObrigatorio="true" :value="dataLinhaSelecionada" label="Informe a data:"
                                        :disabled="bloquearDemaisCamposIniciarLinha">
                                    </inputSingleDay>
                                </div>

                                <div class="col nopadding">
                                    <div class="container nopadding">
                                        <div class="row nopadding">
                                            <div class="col nopadding">
                                                <!-- Input horário inicial -->
                                                <inputSimple label='Hora Inicial' type='time'
                                                    :value='horarioInicialSelecionado'
                                                    @changeInput='changeHorarioInicial'
                                                    :disabled="bloquearDemaisCamposIniciarLinha" />
                                            </div>
                                            <div class="col nopadding">
                                                <!-- Input horário final -->
                                                <inputSimple label='Hora Final' type='time'
                                                    :value='horarioFinalSelecionado' @changeInput='changeHorarioFinal'
                                                    :disabled="bloquearDemaisCamposIniciarLinha" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Input motorista -->
                            <div class="nopadding mt-4 col-sm-6">
                                <selectAll @changeSelect="changeMotorista" :isMultiple="false"
                                    nameForRadio="motoristaSelecionado" :hasSelectAll="false"
                                    :labels="[{ indexDFH: 'MT', description: 'Motorista:*' }]"
                                    :optionsArray="optionsMotoristas" :selected="selectedMotorista" firstSelected="MT"
                                    :loading="loadingCarregandoMotoristas" :disabled="bloquearDemaisCamposIniciarLinha">
                                </selectAll>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <div class="styleBackgroundLinhaIniciada p-2 mb-4">
                            <p class="styleTitleLinhaIniciada row font-bold">Informações de transbordo:</p>
                        </div>

                        <div class="container nopadding">
                            <div class="row nopadding">
                                <!-- Input descarga(ponto) -->
                                <div class="col nopadding">
                                    <selectAll @changeSelect="changeDescargaPonto" :isMultiple="false"
                                        nameForRadio="descargaPontoSelecionado" :hasSelectAll="false"
                                        :labels="[{ indexDFH: 'DP', description: 'Descarga(ponto):' }]"
                                        :optionsArray="optionsDescargaPonto" :selected="selectedDescargaPonto"
                                        firstSelected="DP" :loading="loadingCarregandoDescargaPonto"
                                        :disabled="bloquearDemaisCamposIniciarLinha">
                                    </selectAll>
                                </div>

                                <div class="col nopadding">
                                    <div class="container nopadding">
                                        <div class="row nopadding">
                                            <div class="col-sm-5 nopadding">
                                                <!-- Input ticket -->
                                                <inputSimple label='Ticket:' type='text' @changeInput='changeTicket'
                                                    :value='ticketSelecionado' @keyup.enter="adicionarDescargaPonto"
                                                    :disabled="bloquearDemaisCamposIniciarLinha" />
                                            </div>

                                            <div class="col-5 nopadding">
                                                <!-- Input peso -->
                                                <inputSimple label='Peso(kg):' type='text' @changeInput='changePeso'
                                                    :value='pesoSelecionado' @keyup.enter="adicionarDescargaPonto"
                                                    :disabled="bloquearDemaisCamposIniciarLinha" />
                                            </div>

                                            <div class="col-2 nopadding m-auto w-max">
                                                <SimpleButton :icon="mdiPlus" :sizeIcon="25" event="adicionarDescarga"
                                                    width="45px" height="45px"
                                                    @adicionarDescarga="adicionarDescargaPonto" type="blue"
                                                    extraClass="mt-3" :disabled="bloquearDemaisCamposIniciarLinha" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div v-if="listagensTransbordos.length > 0">
                            <p>Listagem transbordo</p>

                            <div class="styleBackgroundLinhaIniciada p-2 mb-4">
                                <div class="container nopadding">
                                    <div v-for="(transbordo, index) in listagensTransbordos" v-bind:key="index"
                                        class="row nopadding">
                                        <div class="col-sm-6 nopadding row">
                                            <p class="styleTitleLinhaIniciada font-bold">Descarga(ponto):</p>
                                            <p class="styleTitleLinhaIniciada">{{ transbordo.descarga }}</p>
                                        </div>
                                        <div class="col-sm-3 nopadding row">
                                            <p class="styleTitleLinhaIniciada font-bold">Ticket:</p>
                                            <p class="styleTitleLinhaIniciada">{{ transbordo.ticket }}</p>
                                        </div>
                                        <div class="col-sm-3 nopadding row">
                                            <p class="styleTitleLinhaIniciada font-bold">Peso:</p>
                                            <p class="styleTitleLinhaIniciada">{{ transbordo.peso }} kg</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </modalEagle>
        </div>
    </panelEagle>
</template>

<script>
import {
    mdiTelevisionGuide,
    mdiAlert,
    mdiAlertCircleOutline,
    mdiPlus,
    mdiCloseThick,
    mdiRelativeScale,
} from '@mdi/js'
import Vue from 'vue'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
    name: 'MonitoramentoLinhas',

    components: {
        'inputSingleDay': require('@/components/Atom/Datas/InputSingleDay').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'buttonExpand': require('@/components/Atom/Buttons/ButtonExpand').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'subtitle': require('@/components/Atom/Subtitle/Subtitle').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
        'SimpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'monitoramentoLP': require(
            '@/components/Atom/SpecificComponents/MonitoramentoLinhas/MonitoramentoLinhaPrincipal').default,
        'buttonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
    },

    validations: {
        selectedEmpresas: { required },
    },

    data() {
        return {
            mdiAlert: mdiAlert,
            mdiTelevisionGuide: mdiTelevisionGuide,
            mdiAlertCircleOutline: mdiAlertCircleOutline,
            mdiPlus: mdiPlus,
            mdiCloseThick: mdiCloseThick,
            mdiRelativeScale: mdiRelativeScale,
            optionsEmpresas: new EmpresasService().Get(),
            selectedEmpresas: [],
            itemsSubtitleRota: [
                { 'description': 'Operação de acordo com o programado', 'spanColor': 'light-green' },
                { 'description': 'Operação com atraso tolerado', 'spanColor': 'orange' },
                { 'description': 'Operação em atraso/adiantada', 'spanColor': 'red' },
            ],
            itemsSubtitlePontos: [
                { 'description': 'Parou no ponto', 'spanColor': 'light-green' },
                { 'description': 'Pendente', 'spanColor': 'blue' },
                { 'description': 'Não parou no ponto', 'spanColor': 'red' },
            ],
            statusBar: 'info',
            arrayDados: [],
            arrayIndicadores: [],
            lastRequest: '',
            countLinhasPrincipais: 0,
            arrayLinhasPrincipais: [],
            rotasCriadas: [],
            pontosLinha: [],
            atualiza: undefined,
            loadingPanel: false,
            selectedEmpresa: [],
            popoverFiltrosShow: false,
            popoverDadosFiltro: {
                empresas: [],
            },
            arrayButtonsStatus: [
                {
                    'value': 'I', 'title': 'Iniciadas', 'width': '48%',
                    'text': 'Iniciadas', 'isSelected': true
                },
                {
                    'value': 'P', 'title': 'Pendentes', 'width': '48%',
                    'text': 'Pendentes', 'isSelected': false
                },
                {
                    'value': 'F', 'title': 'Finalizadas', 'width': '48%',
                    'text': 'Finalizadas', 'isSelected': false
                },
                {
                    'value': 'C', 'title': 'Canceladas', 'width': '48%',
                    'text': 'Canceladas', 'isSelected': false
                },
            ],
            arrayButtonsTurnos: [
                {
                    'value': 'P', 'title': '1º Turno', 'width': '33%',
                    'text': '1º Turno', 'isSelected': true, 'extraClass': 'm-0'
                },
                {
                    'value': 'S', 'title': '2º Turno', 'width': '33%',
                    'text': '2º Turno', 'isSelected': false, 'extraClass': 'm-0'
                },
                {
                    'value': 'T', 'title': '3º Turno', 'width': '33%',
                    'text': '3º Turno', 'isSelected': false, 'extraClass': 'm-0'
                },
            ],
            filtroSelecionadoStatus: '',
            filtroSelecionadoTurno: '',
            selectedRadio: 1,
            tipoSelecionado: 1,
            selectedVeiculos: [],
            selectedGrupoVeiculos: [],
            veiculosDoBanco: [],
            loadingVeiculos: false,
            dataSelecionada: '',
            popoverData: {
                empresas: [],
                veiculos: [],
                grupos: []
            },
            turnoSelecionado: '',
            linhaSelecionada: '',
            nomeLinhaSelecionada: '',
            escalaSelecionada: '',
            veiculoSelecionado: '',
            dataLinhaSelecionada: '',
            horarioInicialSelecionado: '00:00',
            horarioFinalSelecionado: '23:59',
            motoristaSelecionado: '',
            descargaPontoSelecionado: '',
            descargaPontoSelecionadoNome: '',
            ticketSelecionado: '',
            pesoSelecionado: '',
            optionsTurnos: [
                { 'value': 'P', 'description': '1° Turno' },
                { 'value': 'S', 'description': '2° Turno' },
                { 'value': 'T', 'description': '3° Turno' }
            ],
            selectedTurno: [],
            selectedLinha: [],
            optionsLinhas: [],
            loadingCarregandoLinhas: false,
            loadingCarregandoEscalas: false,
            loadingCarregandoMotoristas: false,
            loadingCarregandoDescargaPonto: false,
            selectedVeiculo: [],
            selectedMotorista: [],
            selectedEscala: [],
            optionsEscalas: [],
            optionsMotoristas: [],
            optionsDescargaPonto: [],
            selectedDescargaPonto: [],
            listagensTransbordos: [],
            bloquearDemaisCamposIniciarLinha: true,
            selectedEmpresaLinha: [],
        }
    },

    beforeDestroy() {
        clearInterval(this.atualiza)
    },

    methods: {
        ...mapGetters(['getMaster']),

        changeEmpresa(empresas) {
            this.selectedEmpresas = empresas;
            this.arrayDados = [];

            this.veiculosDoBanco = [];
            this.grupoVeiculoDoBanco = [];

            this.valueVeiculos = [];
            this.valueGrupoVeiculos = [];

            this.popoverData.grupos = [];
            this.popoverData.veiculos = [];

            this.buscaVeiculosGrupos();
        },

        changeData(data) {
            this.dataSelecionada = data;
            this.popoverData.data = data;
            this.dataSelecionada = data;
        },

        /**
        * @description Adiciona/Remove o veículo selecionado no input veículos.
        * @param {string} veiculo - Veículo selecionado no input.
        * @author Yuri 🇧🇷
        */
        changeSelectVeiculos(veiculo) {
            this.valueVeiculos = veiculo;
            this.selectedGrupoVeiculos = [];
            this.valueGrupoVeiculos = [];
        },

        /**
        * @description Adiciona/Remove o grupo selecionado no input grupos.
        * @param {string} Grupo - Grupo selecionado no input.
        * @author Yuri 🇧🇷
        */
        changeSelectGrupoVeiculos(grupo) {
            this.valueGrupoVeiculos = grupo;
            this.selectedVeiculos = [];
            this.valueVeiculos = [];
        },

        changeStatus(event) {
            this.filtroSelecionadoStatus = event;
        },

        changeTurno(event) {
            this.filtroSelecionadoTurno = event;
        },

        async getInformacoesLinhas() {
            localStorage.removeItem('latitudeAntiga');
            localStorage.removeItem('longitudeAntiga');
            localStorage.removeItem('zoomAntigo');
            localStorage.setItem('atualizandoDados', '0')

            try {
                if (this.selectedEmpresas.length > 0) {
                    this.loadingPanel = true;
                    const body = {
                        'data': this.dataSelecionada,
                        'clientes': this.selectedEmpresas,
                        'veiculos': this.valueVeiculos || [],
                        'grupos': this.valueGrupoVeiculos || [],
                        'status': this.filtroSelecionadoStatus || [],
                        'turno': this.filtroSelecionadoTurno || [],
                        'tipoSelecionado': this.tipoSelecionado
                    }

                    var data = await new HttpRequest().Post('/coletivos/monitoramento/linhas/listar/rotas', body)
                    this.arrayDados = [];
                    this.popoverFiltrosShow = false;

                    if (data.data.rotas.rotas.length > 0) {
                        this.arrayDados = data.data.rotas.rotas;
                        this.pontosLinha = this.arrayDados[0].rotas[0].pontos
                        this.arrayIndicadores = data.data.rotas.indicadores;
                        this.lastRequest = data.data.horario;
                    } else {
                        this.statusBar = 'error';
                    }
                    this.controleIntervalo('criar')
                } else {
                    this.statusBar = 'info';
                    this.arrayDados = [];
                    this.arrayIndicadores = [];
                    this.lastRequest = '';
                }
                this.loadingPanel = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');

                this.loadingPanel = false;
            }
        },

        async buscaVeiculosGrupos() {
            try {
                this.loadingPanelVeiculos = true;
                this.loadingPanelGrupos = true;

                var data = await new HttpRequest().Post('/coletivos/monitoramento/linhas/listar/veiculos/grupos', { empresas: this.selectedEmpresas })

                this.veiculosDoBanco = data.data.veiculos;
                this.grupoVeiculoDoBanco = data.data.grupos;

                this.loadingPanelGrupos = false;
                this.loadingPanelVeiculos = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');

                this.loadingPanelVeiculos = false;
                this.loadingPanelVeiculos = false;
            }
        },

        linhasPrincipaisCriadas(conjuntoLinhas) {
            this.rotasCriadas.push(conjuntoLinhas);
        },

        async atualizaInfo() {
            localStorage.setItem('atualizandoDados', '1')

            if (this.lastRequest != '' && this.selectedEmpresas.length > 0) {
                const body = {
                    'data': this.dataSelecionada,
                    'clientes': this.selectedEmpresas,
                    'veiculos': this.valueVeiculos || [],
                    'grupos': this.valueGrupoVeiculos || [],
                    'status': this.filtroSelecionadoStatus || [],
                    'turno': this.filtroSelecionadoTurno || [],
                    'tipoSelecionado': this.tipoSelecionado
                }
                const rotasAbertas = this.arrayDados;

                var data = await new HttpRequest().Post('/coletivos/monitoramento/linhas/atualizar/info', body);

                this.arrayDados = [];
                this.arrayIndicadores = data.data.rotas.indicadores;
                var novas = data.data.rotas.novas;
                var excluidas = data.data.rotas.excluidas;
                var atualizar = data.data.rotas.atualizar;
                this.lastRequest = data.data.horario;

                await this.novasRotas(novas);
                await this.excluirRotas(excluidas);
                await this.atualizarRotas(atualizar, rotasAbertas);
            } else {
                this.statusBar = 'info';
                this.arrayDados = [];
                this.arrayIndicadores = [];
                this.lastRequest = '';
            }
        },

        novasRotas(novas) {
            var achou = false;
            for (var index in novas) {
                for (var i in this.arrayDados) {
                    if (novas[index].codigo == this.arrayDados[i].codigo) {
                        achou = true;
                        var merged = this.arrayDados[i].rotas.concat(novas[index].rotas);
                        this.arrayDados[i].rotas = merged;
                        this.arrayDados[i].linhasIniciadas = this.arrayDados[i].linhasIniciadas + novas[index].linhasIniciadas;
                    }
                }
            }

            if (!achou) {
                var mergedTwo = this.arrayDados.concat(novas);
                this.arrayDados = mergedTwo;
            }
        },

        excluirRotas(excluidas) {
            for (var a in excluidas) {
                for (var b in this.arrayDados) {
                    for (var c in this.arrayDados[b].rotas) {
                        if (excluidas[a] == this.arrayDados[b].rotas[c].codigo) {
                            this.arrayDados[b].rotas.splice(c, 1);
                            this.arrayDados[b].linhasIniciadas--;
                            if (this.arrayDados[b].linhasIniciadas == 0) {
                                this.arrayDados.splice(b, 1);
                            }
                            break;
                        }
                    }
                }

                for (var d in this.rotasCriadas) {
                    if (excluidas[a] == this.rotasCriadas[d]) {
                        this.rotasCriadas.splice(d, 1);
                    }
                }
            }

        },

        atualizarRotas(atualizar, rotasAbertas) {
            for (var a in atualizar) {
                for (var b in this.arrayDados) {
                    for (var c in this.arrayDados[b].rotas) {
                        if (atualizar[a].rota == this.arrayDados[b].rotas[c].codigo) {
                            this.arrayDados[b].rotas[c].inicioIniciado = atualizar[a].iniciado;
                            this.arrayDados[b].rotas[c].statusOnibus = atualizar[a].statusOnibus;
                            this.arrayDados[b].rotas[c].posicaoBusao = atualizar[a].posicaoOnibus;
                            this.arrayDados[b].rotas[c].passados = atualizar[a].pontosPassados;
                            this.arrayDados[b].rotas[c].porCent = atualizar[a].definePorcentagem;
                            this.arrayDados[b].rotas[c].tempoDeExecucao = atualizar[a].tempoDeExecucao;
                            this.arrayDados[b].rotas[c].kmPercorrido = atualizar[a].kmPercorrido;
                            this.arrayDados[b].rotas[c].rocaClicada = true;

                            for (var e in atualizar[a].pontosAtualizados) {
                                for (var d in this.arrayDados[b].rotas[c].pontos) {
                                    if (atualizar[a].pontosAtualizados[e].ponto == this.arrayDados[b].rotas[c].pontos[d].ponto) {
                                        this.arrayDados[b].rotas[c].pontos[d].status = atualizar[a].pontosAtualizados[e].status
                                        this.arrayDados[b].rotas[c].pontos[d].tempoParado = atualizar[a].pontosAtualizados[e].tempoParado
                                        this.arrayDados[b].rotas[c].pontos[d].kmPercorrido = atualizar[a].pontosAtualizados[e].kmPercorrido
                                        this.arrayDados[b].rotas[c].pontos[d].horaPrevista = atualizar[a].pontosAtualizados[e].horaPrevista
                                        this.arrayDados[b].rotas[c].pontos[d].statusPonto = atualizar[a].pontosAtualizados[e].statusPonto
                                        this.arrayDados[b].rotas[c].pontos[d].mensagem = atualizar[a].pontosAtualizados[e].mensagem
                                        this.arrayDados[b].rotas[c].pontos[d].status = atualizar[a].pontosAtualizados[e].status
                                        this.arrayDados[b].rotas[c].pontos[d].horaPassou = atualizar[a].pontosAtualizados[e].horaPassou
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.arrayDados.map((dados, indexDados) => {
                dados.rotas.map((rota, indexRotas) => {
                    const rotaAtual = rotasAbertas[indexDados].rotas[indexRotas];
                    if (rota.codigo == rotaAtual.codigo) {
                        this.arrayDados[indexDados].rotas[indexRotas].rotaClicada = rotaAtual.rotaClicada;
                        this.arrayDados[indexDados].rotas[indexRotas].mapaAberto = rotaAtual.mapaAberto;
                    }
                })
            })

            this.$refs.monitoramentoLP.forEach(ref => {
                ref.atualizarDadosMapa();
            });
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg   - mensagem que vai aparecer no corpo do toast
         * @param {string} type  - qual o tipo do toast
         * @param {string} title - título para inserir no cabeçalho
         * @author Vitor Hugo 🐨
         */
        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
                title: title,
            });
        },

        /** 
         * Método para atualizar relatório a cada 60s
         * @param String func = string com oq deve ser feito
         * @return Variável atualiza como undefined
         * @author Vitor Hugo
         */
        controleIntervalo(func) {
            if (func == 'criar') {
                if (this.atualiza == undefined) {
                    this.atualiza = setInterval(() => {
                        this.atualizaInfo()
                    }, 60000);
                }
            } else {
                //destruir intervalo
                if (this.atualiza !== undefined) {
                    clearInterval(this.atualiza)
                    this.atualiza = undefined
                }
            }
        },

        //fazer documentação
        filtrar() {
            this.popoverFiltrosShow = false;
        },

        /**
        * @description Evento de fechar o modal, quando este é executado,
        * as informações dos filtros são armazenadas em *this.popoverDadosFiltro*,
        * pois ao fechar e abrir os filtros, deve mostrar as mesmas
        * informações selecionadas. Não consegui salvar o estado do popover.
        * @author Rafa 🐈
        */
        onHidden() {
            if (Array.isArray(this.selectedEmpresas)) {
                this.popoverData.empresas = this.optionsEmpresas.filter((e) => this.selectedEmpresas.includes(e.value))
            }

            if (this.tipoSelecionado == 1) {
                if (Array.isArray(this.valueVeiculos)) {
                    this.popoverData.veiculos = this.veiculosDoBanco.filter((e) => this.valueVeiculos.includes(e.value))
                }
            } else {
                if (Array.isArray(this.valueGrupoVeiculos)) {
                    this.popoverData.grupos = this.grupoVeiculoDoBanco.filter((e) => this.valueGrupoVeiculos.includes(e.value))
                }
            }
        },

        /**
         * Função para selecionar qual tipo de filtro foi 
         selecionado e mostra em tela para o usuário o inpput correspondente,
        * variável que salva qual tipo foi selecionado é 
        this.tipoSelecionado.
        * @param {tipo} number tipo para filtro selecionado, 
        1 = Veículos
        2 = Grupos
        * @author Yuri 🇧🇷
        */
        selecionaTipo(tipo) {
            if (tipo == 1) {
                this.popoverData.grupos = [];
            } else {
                this.popoverData.veiculos = [];
            }

            this.tipoSelecionado = tipo;
        },

        limparFiltros() {
            this.popoverFiltrosShow = false;
        },

        /**
         * @description Inicia uma nova linha de rota coletiva enviando os dados preenchidos no modal e exibe uma mensagem de sucesso ao concluir. 
         * Também reseta os campos e fecha o modal ao final.
         * @throws {Error} Lança um erro caso a solicitação HTTP falhe (dependendo da implementação de `HttpRequest`).
         * @author Yuri 🇧🇷
         */
        async iniciarLinha() {
            const dados = {
                "horarioInicio": this.horarioInicialSelecionado,
                "horarioFim": this.horarioFinalSelecionado,
                "linha": this.linhaSelecionada,
                "listagensTransbordos": this.listagensTransbordos,
                "data": this.dataLinhaSelecionada,
                "motorista": this.motoristaSelecionado,
                "turno": this.turnoSelecionado,
                "escala": this.escalaSelecionada,
                "placa": this.veiculoSelecionado,
                "empresa": this.empresaSelecionadaIniciarLinha
            };
            const url = '/coletivos/monitoramento/linhas/iniciar/nova/rota/coletivo';
            this.$bvModal.hide('modalIniciarLinha');
            this.loadingPanel = true;

            await new HttpRequest().Post(url, dados).then((data) => {
                if (data.status) {
                    this.toastShow('Sucesso!', `Linha ${this.nomeLinhaSelecionada} iniciada com sucesso!`, 'success');
                    this.limparInformacoesModalIniciarLinha();
                }

                this.loadingPanel = false;
            })
        },

        /**
         * @description Fecha o popover de filtros e abre o modal para iniciar uma nova linha.
         * @author Yuri 🇧🇷
         */
        abrirModalIniciarLinha() {
            this.popoverFiltrosShow = false;
            this.$bvModal.show('modalIniciarLinha');
        },

        /**
         * @description Altera o turno selecionado para a linha.
         * @param {string} turno - O turno que será selecionado.
         * @author Yuri 🇧🇷
         */
        changeTurnoLinha(turno) {
            this.turnoSelecionado = turno[0];
            this.validacoesInputsSelecionadosIniciarLinha();
        },

        /**
        * @description Atualiza a linha selecionada e define o nome da linha correspondente.
        * @param {Array} linha - Linha selecionada, onde o primeiro elemento é o valor da linha.
        * @author Yuri 🇧🇷
        */
        changeLinha(linha) {
            this.optionsLinhas.map((linhaSelected) => {
                if (linha[0] == linhaSelected.value) {
                    this.nomeLinhaSelecionada = linhaSelected.description;
                }
            })
            this.linhaSelecionada = linha[0];
            this.validacoesInputsSelecionadosIniciarLinha();

            if (linha[0] == undefined) {
                this.nomeLinhaSelecionada = null;
                this.linhaSelecionada = 0;
            }
        },

        /**
         * @description Consulta as linhas disponíveis para as empresas selecionadas e atualiza as opções de linhas.
         * @author Yuri 🇧🇷
         */
        async consultarLinhas() {
            const dados = {
                "enpresas": this.empresaSelecionadaIniciarLinha
            };
            const url = '/coletivos/monitoramento/linhas/consultar/linhas';
            this.loadingPanel = true;
            this.loadingPanelCarregandoLinhas = true;

            await new HttpRequest().Post(url, dados).then((data) => {
                this.optionsLinhas = data.data.linhas;
            }).finally(() => {
                this.loadingPanel = false;
                this.loadingPanelCarregandoLinhas = false;
            });
        },

        /**
         * @description Fecha o modal de iniciar linha e limpa as informações associadas ao modal.
         * @author Yuri 🇧🇷
         */
        fecharModalIniciarLinha() {
            this.$bvModal.hide('modalIniciarLinha');
        },

        /**
         * @description Limpa as informações.
         * @author Yuri 🇧🇷
         */
        limparInformacoesModalIniciarLinha() {
            this.linhaSelecionada = '';
            this.turnoSelecionado = '';
            this.nomeLinhaSelecionada = '';
            this.veiculoSelecionado = '';
            this.escalaSelecionada = '';
            this.motoristaSelecionado = '';
            this.horarioInicialSelecionado = '00:00';
            this.horarioFinalSelecionado = '23:59';
            this.dataLinhaSelecionada = '';
            this.listagensTransbordos = [];
            this.pesoSelecionado = '';
            this.ticketSelecionado = '';
            this.optionsDescargaPonto = [];
            this.optionsEscalas = [];
            this.optionsLinhas = [];
            this.descargaPontoSelecionadoNome = '';
            this.descargaPontoSelecionado = '';
            this.bloquearDemaisCamposIniciarLinha = true;
            this.selectedDescargaPonto = [];
            this.selectedEscala = [];
            this.selectedLinha = [];
            this.selectedMotorista = [];
            this.selectedVeiculo = [];
            this.selectedTurno = [];
        },

        /**
        * @description Atualiza a escala selecionada.
        * @param {Array} escala - escala selecionada, onde o primeiro elemento é o valor da escala.
        * @author Yuri 🇧🇷
        */
        changeEscala(escala) {
            this.escalaSelecionada = escala[0];
            this.validacoesInputsSelecionadosIniciarLinha();
        },

        /**
        * @description Atualiza o veículo selecionada.
        * @param {Array} veiculo - Veículo selecionado, onde o primeiro elemento é o valor do veículo.
        * @author Yuri 🇧🇷
        */
        changeVeiculoLinha(veiculo) {
            this.veiculoSelecionado = veiculo[0];
            this.validacoesInputsSelecionadosIniciarLinha();
        },

        /**
         * @description Consulta as escalas disponíveis para as empresas selecionadas e atualiza as opções de escalas.
         * @author Yuri 🇧🇷
         */
        async consultarEscalas() {
            const dados = {
                "enpresas": this.empresaSelecionadaIniciarLinha
            };
            const url = '/coletivos/monitoramento/linhas/consultar/escalas';
            this.loadingPanel = true;
            this.loadingPanelCarregandoEscalas = true;

            await new HttpRequest().Post(url, dados).then((data) => {
                this.optionsEscalas = data.data.escalas;
            }).finally(() => {
                this.loadingPanel = false;
                this.loadingPanelCarregandoEscalas = false;
            });
        },

        /**
        * @description Atualiza o horário inicial.
        * @param {Array} horarioInicialSelecionado - Horário inicial selecionado.
        * @author Yuri 🇧🇷
        */
        changeHorarioInicial(horario) {
            this.horarioInicialSelecionado = horario;
        },

        /**
        * @description Atualiza o horário final.
        * @param {Array} horarioFinalSelecionado - Horário final selecionado.
        * @author Yuri 🇧🇷
        */
        changeHorarioFinal(horario) {
            this.horarioFinalSelecionado = horario;

        },

        /**
         * @description Consulta os motoristas disponíveis para as empresas selecionadas e atualiza as opções de motoristas.
         * @author Yuri 🇧🇷
         */
        async consultarMotoristas() {
            const dados = {
                "enpresas": this.empresaSelecionadaIniciarLinha
            };
            const url = '/coletivos/monitoramento/linhas/consultar/motoristas';
            this.loadingPanel = true;
            this.loadingPanelCarregandoMotoristas = true;

            await new HttpRequest().Post(url, dados).then((data) => {
                this.optionsMotoristas = data.data.motoristas;
            }).finally(() => {
                this.loadingPanel = false;
                this.loadingPanelCarregandoMotoristas = false;
            });
        },

        /**
        * @description Atualiza o motorista.
        * @param {Array} motoristaSelecionado - motorista selecionado.
        * @author Yuri 🇧🇷
        */
        changeMotorista(motorista) {
            this.motoristaSelecionado = motorista[0];
        },

        /**
        * @description Atualiza a data da linha.
        * @param {Array} dataLinhaSelecionada - data linha selecionada.
        * @author Yuri 🇧🇷
        */
        changeDataLinha(data) {
            this.dataLinhaSelecionada = data;
        },

        /**
        * @description Atualiza a descarga.
        * @param {Array} descargaPontoSelecionado.
        * @author Yuri 🇧🇷
        */
        changeDescargaPonto(descarga) {
            this.descargaPontoSelecionado = descarga;

            this.optionsDescargaPonto.map((ponto) => {
                if (ponto.value == descarga) {
                    this.descargaPontoSelecionadoNome = ponto.description;
                }
            })
        },

        /**
        * @description Atualiza o ticket.
        * @param {Array} ticketSelecionado.
        * @author Yuri 🇧🇷
        */
        changeTicket(ticket) {
            this.ticketSelecionado = ticket;
        },

        /**
        * @description Atualiza o peso.
        * @param {Array} pesoSelecionado.
        * @author Yuri 🇧🇷
        */
        changePeso(peso) {
            this.pesoSelecionado = peso;
        },

        /**
         * @description Adiciona uma nova descarga ao ponto selecionado, incluindo os detalhes de descarga, ticket e peso, e reseta os campos selecionados.
         * @author Yuri 🇧🇷
         */
        adicionarDescargaPonto() {
            if (this.descargaPontoSelecionadoNome.length <= 0) {
                this.toastShow('Atenção!', 'Informe o campo de descarga(ponto)!', 'warning');
                return
            }

            if (this.ticketSelecionado.length <= 0) {
                this.toastShow('Atenção!', 'Informe o campo de ticket!', 'warning');
                return
            }

            if (this.pesoSelecionado.length <= 0) {
                this.toastShow('Atenção!', 'Informe o campo de peso!', 'warning');
                return
            }

            const objeto = {
                descarga: this.descargaPontoSelecionadoNome,
                ticket: this.ticketSelecionado,
                peso: this.pesoSelecionado,
                transbordo: this.descargaPontoSelecionado[0]
            }

            this.listagensTransbordos.push(objeto);

            this.descargaPontoSelecionadoNome = '';
            this.descargaPontoSelecionado = '';
            this.selectedDescargaPonto = [];
            this.ticketSelecionado = '';
            this.pesoSelecionado = '';
        },

        /**
         * @description Consulta os pontos de descarga disponíveis para as empresas selecionadas e atualiza as opções de motoristas.
         * @author Yuri 🇧🇷
         */
        async consultarPontosDescarga() {
            const dados = {
                "enpresa": this.empresaSelecionadaIniciarLinha,
            };
            const url = '/coletivos/monitoramento/linhas/consultar/pontos/descargas';
            this.loadingPanel = true;
            this.loadingPanelCarregandoDescargaPonto = true;

            await new HttpRequest().Post(url, dados).then((data) => {
                this.optionsDescargaPonto = data.data.pontos;
            }).finally(() => {
                this.loadingPanel = false;
                this.loadingPanelCarregandoDescargaPonto = false;
            });
        },

        /**
        * @description Atualiza o empresa selecionada.
        * @param {Array} empresaSelecionadaIniciarLinha.
        * @author Yuri 🇧🇷
        */
        changeEmpresaSimples(empresa) {
            this.empresaSelecionadaIniciarLinha = empresa;

            this.consultarLinhas();
            this.consultarEscalas();
            this.consultarMotoristas();
            this.limparInformacoesModalIniciarLinha();
            this.buscaVeiculosLinhas();
            this.validacoesInputsSelecionadosIniciarLinha();
        },

        /**
         * Busca os veículos e grupos relacionados às empresas selecionadas.
         * 
         * Realiza uma requisição ao endpoint responsável por listar os veículos e grupos
         * disponíveis, com base na empresa selecionada pelo usuário. Atualiza os estados
         * correspondentes aos dados retornados e controla os indicadores de carregamento
         * para exibição no painel.
         * 
         * * @author Yuri 🇧🇷
         */
        async buscaVeiculosLinhas() {
            try {
                this.loadingPanelVeiculos = true;

                var data = await new HttpRequest().Post('/coletivos/monitoramento/linhas/listar/veiculos/grupos', { empresas: this.empresaSelecionadaIniciarLinha })

                this.veiculosDoBanco = data.data.veiculos;

                this.loadingPanelVeiculos = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');

                this.loadingPanelVeiculos = false;
            }
        },

        /**
         * Valida os campos obrigatórios para iniciar a linha.
         * 
         * Verifica se os campos necessários foram preenchidos corretamente antes de habilitar
         * o restante do fluxo para iniciar uma nova linha. Atualiza o estado do bloqueio
         * de campos e, caso todos os requisitos sejam atendidos, define a data atual para
         * a operação e realiza a consulta de pontos de descarga.
         * 
         * * @author Yuri 🇧🇷
         */
        validacoesInputsSelecionadosIniciarLinha() {
            if (
                this.empresaSelecionadaIniciarLinha.length > 0 &&
                this.turnoSelecionado != undefined &&
                this.turnoSelecionado.length > 0 &&
                this.linhaSelecionada != undefined &&
                this.linhaSelecionada > 0 &&
                this.escalaSelecionada != undefined &&
                this.escalaSelecionada > 0 &&
                this.veiculoSelecionado != undefined &&
                this.veiculoSelecionado.length > 0
            ) {
                this.dataSelecionada = this.consultarDataAtual();
                this.dataLinhaSelecionada = this.consultarDataAtual();
                this.bloquearDemaisCamposIniciarLinha = false;
                this.consultarPontosDescarga();
            } else {
                this.bloquearDemaisCamposIniciarLinha = true;
            }
        },

        /**
         * Obtém a data atual no formato `DD/MM/YYYY`.
         * 
         * Formata a data atual como uma string no padrão dia/mês/ano, utilizando o objeto
         * `Date` para obter os valores de dia, mês e ano. Adiciona zeros à esquerda para
         * garantir que os valores de dia e mês tenham dois dígitos.
         * 
         * * @author Yuri 🇧🇷
             */
        consultarDataAtual() {
            const now = new Date();

            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const year = now.getFullYear();

            return `${day}/${month}/${year}`;
        }
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optionsEmpresas
        }
    }
})
</script>

<style lang="css">
.divListagemLinhas {
    padding-bottom: 10px;
}

.custom-break-line {
    word-break: break-all;
    overflow-wrap: break-word;
}

.styleBackgroundLinhaIniciada {
    background-color: #F0F0F0;
}

.styleTitleLinhaIniciada {
    font-size: 14px;
    text-align: start;
    padding-left: 5px;
}

.font-bold {
    font-weight: 900 !important;
}
</style>