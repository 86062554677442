<!-- 
    * dados:
    NSR - 999999999 (Valor fixo)
    Qtd. reg tipo 2 - Valor da quantidade de registros do tipo 2
    Qtd. reg tipo 3 - Valor da quantidade de registros do tipo 3
    Qtd. reg tipo 4 - Valor da quantidade de registros do tipo 4
    Qtd. reg tipo 5 - Valor da quantidade de registros do tipo 5
    Tipo do registro - 9 (Valor fixo)
 -->
<template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="9" class="nopadding">
                        <span class="newbadge">
                            {{ dados.descricao }}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>Tipo do registro</td>
                    <td>Qtd. reg tipo 1</td>
                    <td>Qtd. reg tipo 2</td>
                    <td>Qtd. reg tipo 3</td>
                    <td>Qtd. reg tipo 4</td>
                    <td>Qtd. reg tipo 5</td>
                    <td>Qtd. reg tipo 6</td>
                    <td>Qtd. reg tipo 7</td>
                    <td>Qtd. reg tipo 8</td>
                </tr>
                <tr class="paddingTd">
                    <td>{{ dados.tipo9[0]['tipoReg'] }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo1 }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo2 }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo3 }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo4 }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo5 }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo6 }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo7 }}</td>
                    <td>{{ dados.tipo9[0].quantidadeRegistrosTipo8 }}</td>
                </tr>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'registroTipo2',
    components: {
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props: {
        dados: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
    },
})
</script>

<style lang="scss"></style>