<!-- 
    * dados:
    NSR - Valor sequencial de registro (clcseq)
    Tipo reg. - 2 (Valor fixo)
    Data e hora gravação - Data e hora da inserção no banco (clcdatahora)
    CPF cadastro - Valor CPF de quem cadastrou (clccpfcadastro)
    Tipo doc. - Valor 1 para CNPJ | 2 para CPF
    Documento - CNPJ | CPF (cldocumento)
    Razão social - razão social da empresa (clnome)
    Local - Local da empresa (cllogradouro+clnumero+clbairro+cinome)
    CRC-16 - Valor verificação cíclica de redundância (será feito futuramente)
 -->
<template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="9" class="nopadding">
                        <span class="newbadge">
                            {{ dados.descricao }}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>Tipo do registro</td>
                    <td>Identificador do vínculo no AEJ </td>
                    <td>Data e hora da marcação</td>
                    <td>Identificador do REP</td>
                    <td>Tipo da marcação</td>
                    <td>Número sequencial do par entrada/saída.</td>
                    <td>Fonte da marcação</td>
                    <td>Código do horário contratual no AEJ</td>
                    <td>Motivo da desconsideração ou inclusão da marcação</td>
                </tr>

                <template v-if="dados.tipo5 && dados.tipo5[0].marcacoes.length">
                    <tr v-for="(item, i) in dados.tipo5[0].marcacoes" :key="i + '_item'">
                        <td>{{ dados.tipo5[0].tipoReg }}</td>
                        <td>{{ dados.tipo5[0].idtVinculoAej }}</td>

                        <td v-if="dados.tipo5[0].jtorigemjornada == 'R'">{{ item.jrldataevento }}</td>
                        <td v-else>{{ item.eadataevento }}</td>

                        <td>{{ dados.tipo5[0].idRepAej }}</td>
                        <td>{{ item.tipoMarcacao }}</td>
                        <td>{{ item.sequencia }}</td>
                        <td>{{ item.fonteMarcacao }}</td>
                        <td>{{ dados.tipo5[0].codHorContratual }}</td>
                        <td>{{ item.motivo }}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr class="paddingTd">
                        <td colspan="9" style="padding:0!important">
                            <div class="tableDataEmpty">
                                <span class="textEmpty">Sem dados</span>
                            </div>
                        </td>
                    </tr>
                </template>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'registroTipo5',
    components: {
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props: {
        dados: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
    }
})
</script>

<style lang="scss"></style>