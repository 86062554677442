<template>
	<panelEagle id="ignicaoLigada" :loading="loadingPanel">
		<div class="col-sm-12 nopadding divDontPrint">
			<div class="col-sm-12 row nopadding">
				<div class="col-sm-6">
					<tituloPage :icon="mdiCarKey" titulo="Tempo de ignição ligada"></tituloPage>
				</div>
				<div class="col-sm-6">
					<basicButtonsRelatoriosEIG :loading="loadingExporta" @exportarRelatorio="exportarRelatorio"
						@gerarRelatorio="gerarRelatorio" :disabledDropdown="$v.dadosTempoInigicaoLigada.$invalid" />
				</div>
			</div>
			<slideUpAndDown>
				<slot slot="filters">
					<div class="col-sm-12">
						<hr>
					</div>
					<div class="col-sm-12 row nopadding mb-2">
						<div class="col-sm-3 nopadding">
							<inputRangeWithLimit titulo="Intervalo*" opens="right" @changeInput="changeData"
								id="dataInicioAcionamento" name="dataInicioAcionamento" />
						</div>
						<div class="col-sm-3 nopadding">
							<selectAll @changeSelect="SelectEmpresas" :isMultiple="true" nameForRadio="radiosEmpresas"
								:labels="labelsSelectEmpresa" :optionsArray="optionsSelectEmpresa" firstSelected="E"
								:selected="selectedEmpresa" :hasSelectAll="true" />
						</div>
						<div class="col-sm-2 nopadding">
							<selectAll :loading="loadingButtonVeiculo" :disabled="!optionsSelectVeiculos.length"
								@selectCriado="selectCriado" @changeSelect="SelectVeiculos" :isMultiple="true"
								@changeCheck="registraCheckveiculos" nameForRadio="radiosVeiculos" :labels="labelsSelectVeiculos"
								:optionsArray="optionsSelectVeiculos" firstSelected="V" :hasSelectAll="true" />
						</div>
						<div class="col-sm-2 nopadding">
							<selectAll :loading="loadingButtonVeiculo" :disabled="!optionsSelectVeiculos.length"
								@selectCriado="selectColaboradoresCriado" @changeSelect="SelectColaboradores" :isMultiple="true"
								nameForRadio="radiosColaboradores" :labels="labelsSelectColaboradores"
								:optionsArray="optionsSelectColaboradores" firstSelected="C" :hasSelectAll="true" />
						</div>

						<div class="col-sm-2 row">
							<span class="col-sm-12"> Visualizar </span>
							<div class="col-sm-6">
								<b-checkbox v-model="checkHorimetro" class='checkBox' id='Horimetro' @change="trocaVizualizar('HR')">
									Horímetro
								</b-checkbox>
							</div>
							<div class="col-sm-6">
								<b-checkbox v-model="checkHodometro" class='checkBox' id='Hodometro' @change="trocaVizualizar('HD')">
									Hodômetro
								</b-checkbox>
							</div>
						</div>
					</div>
				</slot>
			</slideUpAndDown>
		</div>

		<div class="col-sm-12">
			<hr>
		</div>

		<tableRelatorio>
			<slot slot="thead">
				<tr>
					<th class="text-center">Horário inicial</th>
					<th class="text-center" v-if="checkHorimetro">Horímetro inicial</th>
					<th class="text-center" v-if="checkHodometro">Hodômetro inicial</th>
					<th class="text-center">Hora Saída</th>
					<th class="text-center">Hora Chegada</th>
					<th class="text-center" v-if="checkHorimetro">Horímetro final</th>
					<th class="text-center" v-if="checkHodometro">Hodômetro final</th>
					<th class="text-center">Horário final</th>
					<th class="text-center">Duração</th>
					<th class="text-center">Tempo condução</th>
					<th class="text-center">Tempo parado</th>
					<th class="text-center">Tempo estacionado</th>
					<th class="text-center">Distância</th>
					<th class="text-center">Vel média</th>
					<th class="text-center">Vel Máx</th>
					<th class="text-center">{{ dadosTempoInigicaoLigada.veiculos ? 'Veículos' : 'Colaborador' }}</th>
				</tr>
			</slot>

			<slot slot="tbody">
				<br>

				<tr class="totalizador1" v-if="dadosTempoInigicaoLigada.length != 0">
					<td :colspan="checkHorimetro ? 6 : checkHodometro ? 6 : 4"></td>

					<td class="totalizador-dia text-center"><b><i>{{ totalizadoresDias.totalDuracao }}</i></b></td>
					<td class="totalizador-dia text-center"><b><i>{{ totalizadoresDias.totalConducao }}</i></b></td>
					<td class="totalizador-dia text-center"><b><i>{{ totalizadoresDias.totalParado }}</i></b></td>
					<td class="totalizador-dia text-center"><b><i>{{ totalizadoresDias.totalEstacionado }}</i></b></td>
					<td class="totalizador-dia text-center"><b><i>{{ totalizadoresDias.totalDistancia }}</i></b></td>

					<td colspan="4"></td>
				</tr>

				<template v-for="(dados, dIndex) in dadosTempoInigicaoLigada">
					<tr :key="dIndex + dados.placa + '_placa'" class="backColorTdPDF" v-show="dIndex != 'veiculos'">
						<td colspan="14" class="pl-0">
							<span class="newbadge">{{ dIndex }}</span>
						</td>
					</tr>

					<template v-for="(totais, dxIndex) in dados">
						<tr :key="dxIndex.placa" v-show="dxIndex == 'TotaisAgrupados'">

							<td :colspan="checkHorimetro ? 6 : checkHodometro ? 6 : 4" class="totalizador-geral"><b>Totalizador
									Geral</b></td>
							<td class="totalizador-geral text-center"><b>{{ totais.tempoLigadoAgrupado }}</b></td>
							<td class="totalizador-geral text-center"><b>{{ totais.tempoConducaoAgrupado }}</b></td>
							<td class="totalizador-geral text-center"><b>{{ totais.tempoParadoAgrupado }}</b></td>
							<td class="totalizador-geral text-center"><b>{{ totais.tempoEstacionadoAgrupado }}</b></td>
							<td class="totalizador-geral text-center"><b>{{ totais.distanciaTotalAgrupado }}</b></td>
							<td colspan="4" class="totalizador-geral"></td>
						</tr>
					</template>

					<template v-for="(dadosHoras, dhIndex) in dados">
						<tr :key="dIndex + '_' + dadosHoras + '_data_' + dadosHoras.tempoTotalLigado"
							v-show="dhIndex != 'TotaisAgrupados'">
							<td class="dataIgnicao" colspan="14">
								{{ dhIndex }}
							</td>
						</tr>

						<tr :key="`${dIndex}_${dhIndex}_total_${dadosHoras.tempoTotalLigado}`" class="totalizador3"
							v-show="dhIndex != 'TotaisAgrupados'">
							<td :colspan="checkHorimetro ? 6 : checkHodometro ? 6 : 4"></td>
							<td class="totalizador-dia text-center"><b><i>{{ dadosHoras.tempoTotalLigado }}</i></b></td>
							<td class="totalizador-dia text-center"><b><i>{{ dadosHoras.tempoConducaoTotal }}</i></b></td>
							<td class="totalizador-dia text-center"><b><i>{{ dadosHoras.tempoParadoTotal }}</i></b></td>
							<td class="totalizador-dia text-center"><b><i>{{ dadosHoras.tempoEstacionadoTotal }}</i></b></td>
							<td class="totalizador-dia text-center"><b><i>{{ dadosHoras.distanciaTotal }}</i></b></td>
							<td colspan="4"></td>
						</tr>

						<tr v-for="(dadosFinal, dfIndex) in dadosHoras"
							:key="dfIndex + '_dados_' + dadosFinal.horaI + '_' + dIndex + '_' + dhIndex"
							v-show="!['tempoTotalLigado', 'tempoParadoTotal', 'tempoEstacionadoTotal', 'distanciaTotal', 'tempoConducaoTotal', 'TotaisAgrupados'].includes(dfIndex) && dhIndex != 'TotaisAgrupados'">
							<td class="text-center">{{ dadosFinal.horaI || '-' }}</td>
							<td class="text-center" v-if="checkHorimetro">{{ dadosFinal.horimetroInicio || '-' }}</td>
							<td v-if="checkHodometro">{{ dadosFinal.hodometroInicio || '-' }}</td>
							<td class="text-center">{{ dadosFinal.horaSaida || '-' }}</td>
							<td class="text-center">{{ dadosFinal.horaChegada || '-' }} </td>
							<td class="text-center" v-if="checkHorimetro">{{ dadosFinal.horimetroFim || '-' }}</td>
							<td v-if="checkHodometro">{{ dadosFinal.hodometroFim || '-' }}</td>
							<td class="text-center">{{ dadosFinal.horaF || '-' }}</td>
							<td class="text-center">{{ dadosFinal.tempo || '-' }}</td>
							<td class="text-center">{{ dadosFinal.conducao || '-' }}</td>
							<td class="text-center">{{ dadosFinal.parado || '-' }}</td>
							<td class="text-center">{{ dadosFinal.estacionado || '-' }}</td>
							<td class="text-center">{{ dadosFinal.distancia || '-' }}</td>
							<td class="text-center">{{ dadosFinal.velMedia || '-' }}</td>
							<td class="text-center">{{ dadosFinal.velMax || '-' }}</td>
							<td class="text-center">{{ dadosTempoInigicaoLigada.veiculos ? dadosFinal.placa : dadosFinal.motorista }}
							</td>
						</tr>
					</template>
				</template>

				<statusInformation v-if="dadosTempoInigicaoLigada.length == 0" typeBar="tr" :colspanForTd='14'
					:statusBar="statusbar">
				</statusInformation>
			</slot>
		</tableRelatorio>
	</panelEagle>
</template>

<script>
import Vue from 'vue'
import { mdiCarKey } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name: 'tempoIgnicaoLigada',

	validations: {
		empresasArray: { required },
		/*veiculosArray:{required}, */
		colaboradoresArray: { required },
		dadosTempoInigicaoLigada: { required },
	},

	components: {
		/*eslint-disable max-len */
		'tituloPage': require('@/components/Atom/Header/Titulo').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
		'InputRangeWithLimit': require('@/components/Atom/Datas/InputRangeWithLimit').default,
		'SlideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'BasicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
		/*eslint-enable max-len */
	},

	data() {
		return {
			loadingButtonVeiculo: false,
			mdiCarKey: mdiCarKey,
			loadingPanel: false,
			loadingExporta: [false, false, false],
			labelsSelectEmpresa: [
				{ indexDFH: 'E', description: 'Empresas*' },
			],
			labelsSelectColaboradores: [
				{ indexDFH: 'C', description: 'Colaboradores*' },
			],
			optionsSelectEmpresa: new EmpresasService().Get(),
			labelsSelectVeiculos: [
				{ indexDFH: 'V', description: 'Veiculos*' },
				{ indexDFH: 'GV', description: 'Grupos*' },
			],
			statusbar: 'info',
			VeiculosTipo: 'V',
			optionsSelectVeiculos: [],
			optionsSelectColaboradores: [],
			empresasArray: [],
			veiculosArray: [],
			colaboradoresArray: [],
			veiculosSelect: [],
			colaboradoresSelect: [],
			dadosTempoInigicaoLigada: [],
			dataSelecionada: '',
			tamanhoArrayTempoIgnicaoLigada: 0,
			htmlTempoInigicaoLigada: '',
			checkHorimetro: false,
			checkHodometro: false,
			thColaborador: false,
			selectedEmpresa: [],
			selectedColaboradores: [],
			totalizadoresDias: {
				totalDuracao: '',
				totalConducao: '',
				totalParado: '',
				totalEstacionado: '',
				totalDistancia: '',
			},
		}
	},

	methods: {
		...mapGetters(['getMaster']),

		SelectEmpresas(event) {
			this.empresasArray = event
			this.veiculosSelect.clearAll()
			this.colaboradoresSelect.clearAll()
			this.limpaDados()
			this.dados_filtros()
		},

		SelectVeiculos(event) {
			this.veiculosArray = event
			this.limpaDados()
		},

		SelectColaboradores(event) {
			this.colaboradoresArray = event
			this.limpaDados()
		},

		selectCriado(select) {
			this.veiculosSelect = select
		},

		selectColaboradoresCriado(select) {
			this.colaboradoresSelect = select
			this.limpaDados()
		},

		changeData(event) {
			this.dataSelecionada = event
			this.limpaDados()
		},

		registraCheckveiculos(check, needRecharge) {
			this.VeiculosTipo = check
			if (needRecharge) {
				this.dados_filtros()
			}
		},

		limpaDados() {
			this.dadosTempoInigicaoLigada = [];
		},

		async dados_filtros() {
			this.loadingButtonVeiculo = true
			var dados = [];
			dados = await new FiltrosService()
				.dados_filtros(this.empresasArray, [this.VeiculosTipo])
			this.optionsSelectVeiculos = dados[this.VeiculosTipo]

			var dadosColab = [];
			dadosColab = await new FiltrosService()
				.dados_filtros(this.empresasArray, ['C'])
			this.optionsSelectColaboradores = dadosColab['C'];
			this.loadingButtonVeiculo = false
		},

		async gerarRelatorio() {
			this.loadingPanel = true;

			if (this.empresasArray.length > 0 && this.dataSelecionada !== '') {
				this.htmlTempoInigicaoLigada = '';
				this.dadosTempoInigicaoLigada = [];
				this.statusbar = 'info';

				var tipoBusca = this.VeiculosTipo;
				var url = '/telemetria/relatorios/tempo/ignicao/ligada/gerar';
				var data = await new HttpRequest()
					.Post(url,
						{
							'clientes': this.empresasArray,
							'veiculos': this.veiculosArray,
							'colaboradores': this.colaboradoresArray,
							'data_intervalo': this.dataSelecionada,
							'searchVeiculos': tipoBusca
						});

				var dados = data.data;

				this.tamanhoArrayTempoIgnicaoLigada = dados.tamanho;

				if (dados.tamanho > 0) {
					this.dadosTempoInigicaoLigada = dados.tempoIgnicao.dados;
					this.totalizadoresDias = dados.tempoIgnicao.totalizadorGeralDias;
					this.htmlTempoInigicaoLigada = '';
				} else {
					this.htmlTempoInigicaoLigada = '';
					this.dadosTempoInigicaoLigada = [];
					this.statusbar = 'error';
				}
			}
			this.loadingPanel = false
		},

		// Método para descelecionar o outro check quando um for selecionado
		trocaVizualizar(check) {
			if (check === 'HR') {
				this.checkHodometro = false
			} else {
				this.checkHorimetro = false
			}
		},

		/**
		 * @listens exportarRelatorio
		 * @param {'csv'|'xls'|'pdf'} tipo - formato p/ exportação.
		 * @description exporta as informações do relatorio para o formato
		 * desejado.
		 */
		exportarRelatorio(tipo) {
			if (this.tamanhoArrayTempoIgnicaoLigada > 0) {
				var root = process.env.VUE_APP_ROOT;
				var url = '/telemetria/relatorios/tempo/ignicao/ligada/exportar';
				var obj = {
					'arrayDados': this.dadosTempoInigicaoLigada,
					'periodo': this.dataSelecionada,
					'tipo': tipo,
					'horimetro': this.checkHorimetro,
					'hodometro': this.checkHodometro,
					'totalizadores': this.totalizadoresDias
				};

				this.mudaLoading(tipo);

				new HttpRequest().Post(url, obj).then((res) => {
					if (res.status)
						window.open(`${root}/${res.data.local}`)
				}).finally(() => this.mudaLoading())
			}
		},

		/**
		 * @param {'csv'|'xls'|'pdf'|'none'} tipo - formato p/ exportação
		 * @description muda o ícone do tipo sendo exportado p/ sniper
		 */
		mudaLoading(tipo = 'none') {
			switch (tipo) {
				case 'pdf':
					this.loadingExporta = [true, false, false]
					break
				case 'xls':
					this.loadingExporta = [false, true, false]
					break
				case 'csv':
					this.loadingExporta = [false, false, true]
					break
				case 'none':
					this.loadingExporta = [false, false, false]
					break
			}
		}
	},

	mounted() {
		if (!this.getMaster()) {
			this.selectedEmpresa = this.optionsSelectEmpresa
			this.selectedColaboradores = this.optionsSelectColaboradores
		}
	}
})
</script>

<style scoped>
.totalizador-geral {
	padding-top: 10px;
	background-color: #819CF2;
	color: #000000;
}

.totalizador-dia {
	color: #000000;
}

.text-center {
	text-align: center;
}
</style>

<style lang="scss">
#ignicaoLigada {
	font-size: 13px !important;

	.blueIconLocalizar {
		color: #428bca;
		cursor: pointer;
	}

	.dataIgnicao {
		background: #d5d5d5;
		font-weight: 400 !important;
		font-size: 13px;
		vertical-align: middle;
	}

	.totalizador1 {
		background: #A8BAF1;
	}

	.totalizador3 {
		background: #718FEF;
	}
}
</style>