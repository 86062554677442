<template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="9" class="nopadding">
                        <span class="newbadge">
                            {{ dados.descricao }}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>Tipo do registro</td>
                    <td>Identificador do vínculo no AEJ</td>
                    <td>Tipo da ausência ou compensação</td>
                    <td>Data</td>
                    <td>Quantidade de minutos</td>
                    <td>Tipo de movimento no banco de horas</td>
                </tr>

                <template v-if="dados.tipo7 && dados.tipo7.length">
                    <tr v-for="(item, i) in dados.tipo7" :key="i + '_item'">
                        <td>{{ dados.tipo7[0].tipoReg }}</td>
                        <td>{{ dados.tipo7[0].idtVinculoAej }}</td>
                        <td>{{ item.tipoAusenOuComp }}</td>
                        <td>{{ item.data }}</td>
                        <td>{{ item.qtMinutos }}</td>
                        <td>{{ item.tipoMovBh }}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr class="paddingTd">
                        <td colspan="9" style="padding:0!important">
                            <div class="tableDataEmpty">
                                <span class="textEmpty">Sem dados</span>
                            </div>
                        </td>
                    </tr>
                </template>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'registroTipo7',
    components: {
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props: {
        dados: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
    }
})
</script>

<style lang="scss"></style>