<template>
    <panel-eagle id="AEJJornada" :loading='loading'>
        <div class="col-12 row">
            <div class="col-4 nopadding">
                <titulo :icon='mdiClipboardClockOutline' titulo='Arquivo Eletrônico de Jornada' />
            </div>
            <div class="col-8 nopadding divDontPrint">
                <basicButtonsRelatoriosEIG :loading='loadingExportar' :disabledDropdown=true :disabled='$v.$invalid'
                    @gerarRelatorio='gerarRelatorio' :disabledExportar="false">
                </basicButtonsRelatoriosEIG>
            </div>
        </div>
        <slideUpAndDown class="divDontPrint">
            <div class="col-sm-12">
                <hr>
            </div>
            <div class="col-12 nopadding row mb-2">
                <div class="col-4 nopadding">
                    <inputRange :isObrigatorio='true' name='range-extrato' @changeInput='changeIntervalo' />
                </div>
                <div class="col-4 nopadding">
                    <selectAll nameForRadio='selCliente' :isMultiple='false' :hasSelectAll='false'
                        :labels='labelSelectCliente' :optionsArray='optClientes' :selected="selectedCliente"
                        firstSelected='E' :extraClass='{
                            "border border-danger": $v.clienteSelecionado.$anyError
                        }' @close='$v.clienteSelecionado.$touch()' @changeSelect='changeEmpresa' />
                </div>
                <div class="col-4 nopadding">
                    <selectAll @changeSelect="changeColaboradores" :isMultiple="true" :hasSelectAll="true"
                        nameForRadio="SelectColaboradores" :selected=selectedColaboradores
                        :labels="[{ indexDFH: 'CO', description: 'Colaboradores' }]" :optionsArray="optColaboradores"
                        firstSelected="CO" :loading="loadingColaboradores" />
                </div>
            </div>
        </slideUpAndDown>
        <div class="col-sm-12">
            <hr>
        </div>

        <div class="mt-2 col-12 nopadding" v-if="!semDados">
            <registroTipo1 :dados="this.tableData.data.objRetorno.tipo1"></registroTipo1>
            <registroTipo2 :dados="this.tableData.data.objRetorno.tipo2"></registroTipo2>
            <registroTipo3 :dados="this.tableData.data.objRetorno.tipo3"></registroTipo3>
            <registroTipo4 :dados="this.tableData.data.objRetorno.tipo4"></registroTipo4>
            <registroTipo5 :dados="this.tableData.data.objRetorno.tipo5"></registroTipo5>
            <registroTipo6 :dados="this.tableData.data.objRetorno.tipo6"></registroTipo6>
            <registroTipo7 :dados="this.tableData.data.objRetorno.tipo7"></registroTipo7>
            <registroTipo8 :dados="this.tableData.data.objRetorno.tipo8"></registroTipo8>
            <registroTipo9 :dados="this.tableData.data.objRetorno.tipo9"></registroTipo9>
        </div>

        <div v-else class="statusBar">
            <statusInformation typeBar='div' :statusBar='statusBar' />
        </div>
        <requiredFields class="divDontPrint" />
        <div class="col-sm-12">
            <hr>
        </div>
    </panel-eagle>
</template>

<script>
import { mdiClipboardClockOutline } from '@mdi/js';
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { isArray } from '@vue/shared';
import { mapGetters } from 'vuex'
import { FiltrosService } from '@/Services/filtros/filtros.Service'

export default {
    name: 'AEJJornada',
    components: {
        slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        basicButtonsRelatoriosEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        titulo: require('@/components/Atom/Header/Titulo').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        inputRange: require('@/components/Atom/Datas/InputRangeWithLimit').default,
        statusInformation: require('@/components/Atom/StatusInformation/StatusInformation').default,
        requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
        registroTipo1: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo1').default,
        registroTipo2: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo2').default,
        registroTipo3: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo3').default,
        registroTipo4: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo4').default,
        registroTipo5: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo5').default,
        registroTipo6: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo6').default,
        registroTipo7: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo7').default,
        registroTipo8: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo8').default,
        registroTipo9: require('@/components/Atom/SpecificComponents/RelatorioAEJ/RegistroAEJTipo9').default,
    },

    data() {
        return {
            mdiClipboardClockOutline: mdiClipboardClockOutline,
            link: '/controle/jornada/relatorios/aej/',
            statusBar: 'info',
            loading: false,
            loadingExportar: [false, false, false],
            tableData: [],
            optClientes: new EmpresasService().Get(),
            labelSelectCliente: [
                { indexDFH: 'E', description: 'Empresa*' }
            ],
            clienteSelecionado: [],
            selectedCliente: [],
            intervalo: '',
            selectedColaboradores: [],
            optColaboradores: [],
            colaboradores: [],
            loadingColaboradores: false,
        }
    },

    validations: {
        clienteSelecionado: { required },
    },

    methods: {
        ...mapGetters(['getMaster']),

        /**
         * @listens changeSelect
         * @param {number[]} arr - cliente selecionado
         * @description muda o cliente selecionado
         */
        changeEmpresa(arr) {
            this.clienteSelecionado = arr
            this.buscaColaboradores(this.clienteSelecionado)
            this.tableData = []
        },

        /** 
         * Método para buscar os veículos do cliente
         * @param {array} empresa = código da empresa
         * @author Vitor Hugo 🐨
         */
        buscaColaboradores(empresa) {
            this.loadingColaboradores = true
            new FiltrosService().dados_filtros(empresa, ['C'])
                .then((dados) => {
                    this.optColaboradores = dados.C
                })
            this.loadingColaboradores = false
        },

        changeColaboradores(value) {
            this.colaboradores = value
        },

        /**
         * @listens changeInput
         * @param {string} intervalo formato dd/mm/yyyy - dd/mm/yyyy
         */
        changeIntervalo(intervalo) {
            this.intervalo = intervalo
            this.tableData = []
        },

        /**
         * @description carrega informações do relatório e atribui os dados a tableData. 
         * @listens gerarRelatorio
         */
        gerarRelatorio() {
            var url = `${this.link}gerarRelatorio`
            var obj = {
                intervalo: this.intervalo,
                empresa: this.clienteSelecionado[0],
                colaboradores: this.colaboradores,
            }
            this.tableData = []
            var root = process.env.VUE_APP_ROOT

            this.loading = true
            //Comentado para nao dar erro com o backend
            new HttpRequest().Post(url, obj)
                .then((objRetorno) => {
                    if (objRetorno.status) {
                        this.tableData = objRetorno

                        this.downloadAFD(objRetorno.data.arquivoAEJ)

                        window.open(root + '/' + 'arquivo_p7s.pfx')

                        if (Object.values(this.tableData).length == 0) {
                            this.statusBar = 'error';
                        } else {
                            this.statusbar = 'error2'
                        }
                    } else {
                        this.statusBar = 'error';
                        conectionError()
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loading = false)

        },

        /**
         * @description Faz o download de arquivo txt no navegador.
         * @param {String} text 
         */
        downloadAFD(text) {
            let newBlob = new Blob([text], { type: "text/plain" })
            var FILE = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = FILE;
            link.setAttribute('download', 'AEJ.txt')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },

        /**
         * @description Faz o download de arquivo pfx no navegador.
         * @param {String} text 
         */
        downloadAssinaturaDigital(text) {
            let newBlob = new Blob([text], { type: "text/plain" })
            var FILE = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = FILE;
            link.setAttribute('download', 'ARQUIVO_P7S.pfx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },

        /**
         * @description exporta os dados no formato pdf|xls|csv|txt
         * @listens exportarRelatorio
         * @param {'pdf'|'xls'|'csv'|'txt'} formato
         */
        exportarRelatorio(formato) {
            let uri = `${this.link}exportar`
            let obj = {
                empresa: this.findNomeEmpresa(),
                intervalo: this.intervalo,
                arrayDados: this.tableData,
                tipo: formato
            }
            this.decideExportar(formato)
            new HttpRequest().Post(uri, obj)
                .then((res) => {
                    if (res.status) {
                        if (formato == 'txt') {
                            this.downloadAFD(res.data.local)
                        } else {
                            var root = process.env.VUE_APP_ROOT;
                            open(root + '/' + res.data.local)
                        }
                    } else {
                        conectionError()
                    }
                })
                .finally(() => this.decideExportar('none'))
        },
    },

    computed: {
        /**
         * @description verifica se existem dados para validar os botões e listagem
         */
        semDados() {
            return isArray(this.tableData) && !this.tableData.length
                || !Object.values(this.tableData).length
        }
    },

    mounted() {
        if (!this.getMaster()) {
            if (this.optClientes && this.optClientes.length) {
                this.selectedCliente = [this.optClientes[0]]
            }
        }
    },
}
</script>

<style lang='scss'>
#AEJJornada {
    .sc-table {
        border: 1px solid #ddd;

        .el-table {
            margin: 0 !important;
        }
    }

    .tableDataEmpty {
        padding: 0px !important;
        font-size: 14px;
        color: #FFF;
        text-align: center;
        background: #e04b4a !important;

        .textEmpty {
            color: #FFF !important;
            line-height: 60px;
        }
    }

    .trTdHeader {
        background-color: #ccc;

        td {
            padding: 5px 0.5rem 0 0.5rem !important;
            ;
        }
    }

    #tableListagem {
        .el-table th {
            background-color: #ccc !important;
            border: 1px solid #e5e5e5;
            padding: 0 !important;

            .caret-wrapper {
                height: 30px;
            }
        }

        .el-table__row td {
            background-color: #efefef !important;
            border: 1px solid #e5e5e5 !important;
        }
    }

    .tableFixHead tr:not(.backColorTdPDF) td {
        padding: 5px 0.5rem 0 0.5rem !important;
        ;
    }
}
</style>