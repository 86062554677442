<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <tableSimples @fimDaRolagem="pegaProximaPagina">
    <thead>
      <tr>
        <th>Data processamento</th>
        <th>Data evento</th>
        <th>Evento</th>
        <th>Ponto</th>
        <th>Região</th>
        <th>Endereço</th>
        <th>Latitude, Longitude</th>
        <th>Hodômetro</th>
        <th>Ignição</th>
        <th>Km/h</th>
        <th>Bateria</th>
        <th>Motorista</th>
        <th>Localizar</th>
      </tr>
    </thead>
    <tbody v-if="mostrarDados">
      <template v-for="(veiculo, index) in relatorio.dados" ref="tableSize">
        <tr :key="`${index}__placa`" class="linhaPlaca">
          <td colspan="13" class="p-0 pt-3">
            <span class="placaExcesso">
              {{ veiculo.index }}
            </span>
          </td>
        </tr>
        <tr v-for="(reg, i) in veiculo.registros" :key="`${i}_${index}_${index}`" class="trStyle">
          <td>{{ reg.dataprocessado }}</td>
          <td style="width: 165px; text-align: center;">{{ formataDataHora(reg.dataevento) }}</td>
          <td :style="validaAlerta(reg)">
            <baseIcon style="margin-right: 5px" size="20" class="" :icon="decideIconeMotivoTransmissao(reg.origem)" />
            {{ reg.motivotransmissao }}
          </td>
          <td>{{ reg.ponto }}</td>
          <td>{{ reg.regiao }}</td>
          <td>{{ reg.endereco }}</td>
          <td>{{ reg.latlng }}</td>
          <td>{{ reg.hodometro }}</td>
          <td>
            <baseIcon v-if="reg.origem == 'bilhetes'" v-bind="defineIgnicao(reg.ignicao)" />
          </td>
          <td>{{ reg.kmh }}</td>
          <td style="width: 4.5%">
            <span v-if="reg.bateria === null">-</span>
            <span v-else-if="reg.bateria >= 0 && reg.origem == 'bilhetes'">
              <baseIcon style="color: green" v-bind="defineIconeBateria(reg.bateria)" />
              {{ reg.bateria }}%
            </span>
          </td>
          <td>
            {{ reg.condutor }}
          </td>
          <td>
            <span v-if="reg.latlng" class="botao" event="click" @click="linkGoogleMaps(reg.latlng)">
              <baseIcon size="18" class="cursor-pointer corIcone" :icon="mdiTarget" />
            </span>
          </td>
        </tr>
      </template>
    </tbody>
    <tbody v-else>
      <statusBar :statusBar="relatorio.info" :colspanForTd="13" />
    </tbody>
  </tableSimples>
</template>
<script>
import Vue from 'vue'
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { DateTime } from "luxon";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiBattery30,
  mdiBattery60,
  mdiBattery90,
  mdiBattery,
  mdiTarget,
  mdiCarConnected,    
  mdiCellphoneMessage,
  mdiDrawPen
} from "@mdi/js";
export default Vue.extend({
  name: 'TablesSimplesDetalhado',

  components: {
    baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
    statusBar: require("@/components/Atom/StatusInformation/StatusInformation").default,
    tableSimples: require("@/components/Atom/Table/TableSimples").default,
  },

  data() {
    return {
      mdiCheckCircle: mdiCheckCircle,
      mdiCloseCircle: mdiCloseCircle,
      mdiBattery30: mdiBattery30,
      mdiBattery60: mdiBattery60,
      mdiBattery90: mdiBattery90,
      mdiBattery: mdiBattery,
      mdiTarget: mdiTarget,
      mdiCarConnected: mdiCarConnected,
      mdiCellphoneMessage: mdiCellphoneMessage,
      mdiDrawPen: mdiDrawPen,
      next_page: null,
      loading: false,
      url: '/telemetria/relatorios/eventos/veiculo/',
      paramsCopia: {},
    }
  },

  props: {
    relatorio: {
      required: true,
    },
    mostrarDados: {
      required: true,
    },
    alertas: {
      required: true
    },
    nextPage: {
      type: String,
      default: null
    }
  },
  watch: {
    nextPage(newVal) {
      this.next_page = newVal;
    }
  },
  methods: {
    /**
     * Essa função pega a próxima pagina 
     * e faz a requisição da mesma.
     * Depois eu faço umas gambiarrinhas para
     * poder juntar os valores hehe...
     * Sei que dava para melhorar essas função 
     * futuramente ._.
     * @author Marcos
    */
    pegaProximaPagina() {
      if (!this.loading && this.next_page && this.relatorio.dados[0] != undefined) {
        // eslint-disable-next-line vue/no-mutating-props
        this.relatorio.loading = true
        this.loading = true;
        let obj = this.paramsCopia;
        let regex = /\d+.?$/
        let number = regex.exec(this.next_page)
        let urlProximaPagina = this.url + 'gerar?page=' + number[0]
        new HttpRequest().Post(urlProximaPagina, obj)
          .then((response) => {
            this.next_page = response.data.next_page_url ?? false
            const dados = Object.values(response.data.data)
            const dadosSemUltimo = dados.slice(0, -1)
            // eslint-disable-next-line vue/no-mutating-props
            this.relatorio.dados[0].registros =
              this.relatorio.dados[0].registros.concat(dadosSemUltimo)
          })
          .catch(() => {
            conectionError()
          })
          .finally(() => {
            // eslint-disable-next-line vue/no-mutating-props
            this.relatorio.loading = false;
            this.loading = false;
          });
      }
    },

    /** 
     * formata a hora de  yyyy-LL-dd HH:mm:ss para dd/LL/yyyy HH:mm:ss
     * @author Otávio 🦆 
    */
    formataDataHora(dataHora) {
      if (dataHora) {
        var data = DateTime.fromFormat(dataHora, "yyyy-LL-dd HH:mm:ss").toFormat("dd/LL/yyyy HH:mm:ss");
        return data;
      }
      return null;
    },

    /**
     * Apenas valida se o motivo de transmissão
     * esta dentro do array de alertas ou se o registro é uma tratativa.
     * Se estiver, retorna a cor da fonte em vermelho.
     */
    validaAlerta(registro) {
      if (this.alertas.includes(registro.evento)) return "color:#D94D45";
      return "";
    },

    decideIconeMotivoTransmissao(origem) {
      switch (origem) {
        case "bilhetes":
          return this.mdiCarConnected;
        case "comandos":
          return this.mdiCellphoneMessage;
        case "tratativas_alerta":
          return this.mdiDrawPen;
        default:
          break;
      }
    },

    defineIgnicao(ignicao) {
      if (ignicao) {
        return {
          size: 18,
          icon: mdiCheckCircle,
          style: "color: #5BC936",
        };
      } else {
        return {
          size: 18,
          icon: mdiCloseCircle,
          style: "color: #D94D45",
        };
      }
    },

    defineIconeBateria(porcentagemBateria) {
      if (porcentagemBateria > 90) {
        return {
          icon: mdiBattery,
          size: 14,
          style: "color: #5BC936",
        };
      } else if (porcentagemBateria <= 90 && porcentagemBateria > 60) {
        return {
          icon: mdiBattery90,
          size: 14,
          style: "color: #5BC936",
        };
      } else if (porcentagemBateria <= 60 && porcentagemBateria > 30) {
        return {
          icon: mdiBattery60,
          size: 14,
          style: "color: #DECC47",
        };
      } else if (porcentagemBateria <= 30) {
        return {
          icon: mdiBattery30,
          size: 14,
          style: "color: #D94D45",
        };
      }
    },

    linkGoogleMaps(value) {
      let latlng = value?.split(",") ?? [0, 0];
      window.open(`https://www.google.com/maps/place/${latlng[0]},${latlng[1]}`);
    },
  },
})
</script>

<style lang="css" scoped>
.linhaPlaca {
  margin-top: 10px;
  background-color: #fff !important;
}

.placaExcesso {
  width: 300px;
  background-color: #777;
  padding-left: 0px;
  padding-right: auto;
  color: white;
  padding-top: 4x !important;
  font-size: 15px !important;
  text-align: center !important;
  margin-top: 15px;
  height: 30px;
  vertical-align: middle;
  display: table-cell;
}

.trStyle {
  font-size: 12px;
  background: #eee !important;
}

td {
  padding-left: 5px;
  background: #eee !important;
}

.botao::before {
  font-size: 16px;
  cursor: pointer;
}


.corIcone {
  color: #2196f3;
}
</style>
