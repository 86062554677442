var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mapaSimples",attrs:{"id":"mapaSimples"}},[_c('l-map',_vm._g(_vm._b({ref:"mapaSimples",style:(_vm.defineEstiloMapa()),attrs:{"worldCopyJump":true,"zoom":_vm.zoomAntigo,"center":_vm.center,"options":{
            scrollWheelZoom: _vm.scrollWheelZoom,
            zoomControl: false,
            dragging: this.dragging,
            editable: true,
            trackResize: true,
        }},on:{"update:zoom":_vm.zoomUpdated,"click":_vm.clicaMapa}},'l-map',_vm.$attrs,false),_vm.$listeners),[(_vm.showZoom)?_c('l-control-layers',{attrs:{"position":_vm.posicaoControles,"collapsed":true}}):_vm._e(),_vm._l((_vm.tileProviders),function(tileProvider){return _c('l-tile-layer',_vm._b({key:tileProvider.name,attrs:{"options":{
            maxZoom: tileProvider.maxZoom,
        },"layer-type":"base"}},'l-tile-layer',tileProvider,false))}),(_vm.showZoom)?_c('l-control-zoom',{attrs:{"position":_vm.posicaoControles}}):_vm._e(),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }