<template>
	<div class="col-sm-12 row nopadding" id="tabsLinhasEscolherPontos">
		<b-overlay :show="loadingMapa" class="col-sm-12 row nopadding">
			<div class="col-sm-4 nopadding mt-3" v-show="expandirMapa">
				<div class="col-sm-12 nopadding">
					<SelectAll :loading="controleLoading" :disabled="tipoPonto" ref="refBuscarPontos"
						nameForRadio="seletorDePontos" :labels="[{ description: 'Buscar Pontos' }]" :isMultiple="false"
						:optionsArray="optBuscarPontos" @changeSelect="changeBuscarPontos" class='pl-0' />
				</div>

				<!-- lista de pontos' -->
				<b-list-group class="listaDePontos col-sm-12 noppadding">
					<Draggable v-model="pontosLinha" @change="mudaDrag">
						<template v-for="(ponto, index) in pontosLinha">
							<div :key="index" class="
									borda-linha 
									text-left 
									flex-container
								" :class="(index % 2) ? 'back-claro' : 'back-escuro'">
								<div class="flex-container_1">
									<span class="index">
										{{ index + 1 }}
									</span>

									<img v-bind="defineImagem(ponto.potipo)" />

									<span @click="direcionaMapa(ponto)" class="cross my-auto ml-1 mr-1 cursor-pointer"
										title="Direcionar ate o ponto">
										<BaseIcon size='18' class="cross my-auto ml-1 mr-1" :icon="mdiTarget" />
									</span>

									<span class="descricao my-auto" :title="ponto.podescricao">
										{{ ponto.podescricao }}
									</span>
								</div>

								<div class="flex-container_2 text-left">
									<span class="tempoEstimado my-auto text-left">
										{{ ponto.pltempoestimado }}
									</span>

									<div class="all_botoes nowrap text-left">
										<span title="Definir como ponto de retorno" class="cursor-pointer" @click="pontoRetorno(index)">
											<BaseIcon size='18' class="mr-2 ml-2" :icon="mdiUndoVariant" :class="ponto.plretorno ?
												'botoes plretornoBlue' :
												'botoes plretorno'" />
										</span>

										<span title="Considerar quilometragem morta" @click="kmMorto(index)">
											<BaseIcon size='18' class="mr-2 ml-2" :icon="mdiMapMarkerCircle" :class="pontosLinha[index].plconsiderarkmmorta ?
												'botoes plconsiderarkmmortaBlue' :
												'botoes plconsiderarkmmorta'
												" />
										</span>

										<span title="Remover" @click="removerPonto(index)">
											<BaseIcon size='18' class="botoes mr-2 ml-2" :icon="mdiCloseCircleOutline" />
										</span>
									</div>
								</div>
							</div>
						</template>
					</Draggable>
				</b-list-group>
			</div>

			<div class="row nopadding mt-3" :class="expandirMapa ? 'col-sm-8' : 'col-sm-12'">
				<!-- checkbox -->
				<div class="col-sm-12 nopadding row justify-content-center">
					<!-- :disabled="linha.licliente == ''" -->
					<b-checkbox-group class="checkMapa" :options="optionsCheckBox" v-model="checkSelecionados" inline
						@change="listarPontos" />
				</div>

				<!-- mapa -->
				<div class="col-sm-12 nopadding" style="z-index: 5">
					<MapaSimples height="450" ref="refMapa" @contextmenu="(v) => { return }">
						<div class="col-sm-1 divDentroMapa">
							<div class="infoMapa">
								Distância total: {{ distanciaTotal }} km<br />
								Quantidade de pontos: {{ quantidadeDePontos }}<br />
								Tempo Estimado:{{ tempoEstimado }}
								<br />
							</div>

							<div class="col-sm-1 nopadding align-baseline">
								<SimpleButton v-show="true" type="red" :icon="mdiTrashCanOutline" width="60px" title="Limpar traçado"
									class="" event="click" @click="limparPontos" />
							</div>

							<div class="buttonMap" @click="abreEfechaMapa()" :title="expandirMapa ? 'Expandir mapa' : 'Exibir lista de pontos'
								">
								<div class="icone" :class="expandirMapa ? '' : 'rotaciona'">
									<BaseIcon size='30' class="chevronMapa ml-2" :icon="mdiArrowCollapseLeft" />
								</div>
							</div>
						</div>

						<template>
							<!-- RASTRO DA LINHA -->
							<div>
								<PolilinhaEditavel :poliEditavel="polilinhaEditavel" :latLng="polinhaRastro"
									@rotaClick="editarLinhaPoliEditavel" />
							</div>

							<template v-for="(ponto, index) in pontosLinha">
								{{ ponto + ' ' + index }}
								<MarkerPoint :icon="`<div class='marker-pin'>
											<i>
												<div class='centroMarker'>
													${index + 1}
												</div>
											</i>
										</div>`" :latitude="ponto.polatitude" :longitude="ponto.polongitude" :key="'pontosLinha' + index"
									style="z-index=100;" typeIcon="divIcon">
									<PopupMarker>
										<div class="flex_pop_up">
											{{ ponto.podescricao }}
											<SimpleButton text="Remover" type="red" :icon="mdiTrashCanOutline" event="click"
												@click="removerPonto(index)" />
										</div>
									</PopupMarker>
								</MarkerPoint>
							</template>

							<!-- TODOS OS PONTOS -->
							<template v-for="(ponto, index) in pontosTotal">
								<MarkerPoint :key="'pontosTotal' + index" :latitude="ponto.polatitude" :longitude="ponto.polongitude"
									:icon="decideIcone(ponto.potipo)" style="z-index=99;">
									<PopupMarker>
										<div class="row d-flex justify-content-center">
											{{ ponto.podescricao }}
											<SimpleButton text="Adicionar" type="blue" event="click" @click="adicionarPontoCheckBox(index)" />
										</div>
									</PopupMarker>
								</MarkerPoint>
							</template>
						</template>
					</MapaSimples>
				</div>
			</div>
		</b-overlay>
	</div>
</template>

<script>
import {
	mdiCloseCircleOutline,
	mdiArrowCollapseLeft,
	mdiMapMarkerCircle,
	mdiTrashCanOutline,
	mdiSwapHorizontal,
	mdiUndoVariant,
	mdiTarget
} from '@mdi/js'
import {
	latLngMaisProximo,
	distanciaLatLonEmKm
} from '@/Services/Helpers/latitudeLongitudeHelper'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import Vue from 'vue'
import Draggable from 'vuedraggable'
import { conectionError } from '@/Services/Helpers/swellHeper'
var decodePoly = require('@mapbox/polyline')

export default Vue.extend({
	name: 'tabsLinhasEscolherPontos',

	props: {
		buscarPontosProp: {
			type: Array,
			default: function () { return [] }
		},

		cliente: {
			type: [String, Number],
			default: ''
		},

		velocidadeMedia: {
			type: Number,
			default: 40
		},

		tipoPonto: {
			type: Boolean,
			default: false
		},

		controleLoading: {
			type: Boolean,
			default: false
		},

		tipoPontoSelecionadoNumber: {
			type: Number,
			required: false,
		},

		editandoLinhaProp: {
			type: Boolean,
			required: false,
		},

		selectedTipo: {
			type: Array,
			required: false,
		},

		inputPontoSelecionado: {
			type: Number,
			required: false,
		}
	},

	components: {
		Draggable,
		SelectAll: require('@/components/Atom/Select/SelectAll.vue').default,
		PopupMarker: require('@/components/Atom/Mapa/PopupMarker.vue').default,
		MarkerPoint: require('@/components/Atom/Mapa/MarkerPoint.vue').default,
		PolilinhaEditavel: require('@/components/Atom/Mapa/PolilinhaEditavel.vue').default,
		SimpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		MapaSimples: require('@/components/Atom/Mapa/MapaSimples.vue').default,
		BaseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
	},

	data() {
		return {
			mdiCloseCircleOutline: mdiCloseCircleOutline,
			mdiArrowCollapseLeft: mdiArrowCollapseLeft,
			mdiTrashCanOutline: mdiTrashCanOutline,
			mdiMapMarkerCircle: mdiMapMarkerCircle,
			mdiSwapHorizontal: mdiSwapHorizontal,
			mdiUndoVariant: mdiUndoVariant,
			mdiTarget: mdiTarget,

			// controle
			tamDivMap: '8',
			polilinhaEditavel: false,
			baseUri: '/coletivos/cadastros/linhas/',
			loadingMapa: false,

			polinhaRastro: [],

			optBuscarPontos: [],

			pontosLinha: [],

			tempoEstimado: '00:00:00',
			quantidadeDePontos: '0',
			distanciaTotal: '0.00',
			expandirMapa: true,
			optionsCheckBox: [
				{ text: 'Pontos de refência', value: 'P' },
				{ text: 'Pontos de coleta', value: 'C' },
				{ text: 'Pontos de entrega', value: 'E' },
				// caso algum dia precise por dnv
				// {text:'Regiões',            value:'reg'},
			],
			checkSelecionados: [],
			pontosTotal: [],

			rotaLinha: null,
		}
	},

	mounted() {
		this.$refs.refMapa.validateSize()
	},

	watch: {
		comPontos(val) {
			if (val) {
				this.limparPontos()
			}
		},

		buscarPontosProp() {
			this.optBuscarPontos = this.buscarPontosProp
			// this.limparPontos()
		},

		velocidadeMedia() {
			let velocidade = 0;

			if (this.rotaLinha != null) {
				if (this.velocidadeMedia <= 0) {
					velocidade = 40;
				} else {
					velocidade = this.velocidadeMedia;
				}

				this.tempoEstimadoPorPonto(this.rotaLinha.data[0].routes[0].legs, velocidade);
				this.tempoEstimadoTotal(this.rotaLinha.data[0].routes[0].distance, velocidade);
			}

			this.calculaDistanciaEhora()
		},

		/**
		* Função para monitorar a variável inputPontoSelecionado, quando ela for modificada, variável essa
		* que recebe informação de quando o select de tipo for alterado, quando ele for alterado chama a
		* função this.limparPontos.
		* @author Desconhecido 
		*/
		inputPontoSelecionado() {
			this.limparPontos();
		},

		/**
		* Função para monitorar a variável polinhaRastro, quando ela for modificada, realiza um emit para a
		* tela pai, CadastroLinhas.vue e valida a tab 3 como concluída.
		* @author Desconhecido 
		*/
		polinhaRastro() {
			this.$nextTick(() => {
				this.$emit('buscarPontos', [
					this.polinhaRastro, this.distanciaTotal
				], 'tab-1')
			})

		},
	},

	methods: {
		abreEfechaMapa() {
			this.$refs.refMapa.validateSize()
			this.$nextTick(() => {
				this.expandirMapa = !this.expandirMapa
			})
		},

		arrumaValoresEditar(dados) {
			this.$nextTick(() => {
				if (dados.linha.listringrota != null)
					this.polinhaRastro = decodePoly.decode(dados.linha.listringrota)
				this.pontosLinha = dados.podados
				this.quantidadeDePontos = this.pontosLinha.length
				const lidistancia = dados.linha.lidistancia
				const litempoestimado = dados.linha.litempoestimado
				if (!lidistancia) this.calculaDistanciaEhora()
				else {
					this.distanciaTotal = lidistancia
					this.tempoEstimado = litempoestimado
				}
			})
		},

		/**
		 * Função para salvar os dados gerador com o rastro da linha, após isso retorna os
		 * dados da nova linha gerada ou da linha editada.
		 * @return obj, dados gerador do rastro da nova linha ou linha editada
		 * @author Desconhecido 
		*/
		emiteValores() {
			var obj = {
				'ratro': decodePoly.encode(this.polinhaRastro),
				'pontos': this.pontosLinha,
				'distancia': this.distanciaTotal,
				'tempo': this.tempoEstimado,
			}

			return obj;
		},

		copiaRastro(value) {
			this.$nextTick(() => {
				this.polinhaRastro = value
				this.calculaDistanciaEhora()
			})
		},

		/**
		 * Busca pelos pontos e regiões do cliente de acordo com
		 * os checkboxes que estão selecionados em cima do mapa
		 */
		listarPontos() {
			this.loadingMapa = true
			this.pontosTotal = []
			this.allRegioes = []
			let uri = this.baseUri + 'lista/pontos'
			this.$nextTick(function () {
				let obj = {
					cliente: this.cliente,
					tipos: this.checkSelecionados,
				}
				new HttpRequest().Post(uri, obj).then((dados) => {
					this.pontosTotal = dados.data.pontos
					this.loadingMapa = false
				})
			})
		},

		adicionarPontoCheckBox(index) {
			this.fecharPopUp();
			this.changeBuscarPontos([this.pontosTotal[index].pocodigo]);
			this.pontosTotal.splice(index, 1);
		},

		decideIcone(tipo) {
			switch (tipo) {
				case 'C': return 'img/markerPontoC.png';
				case 'E': return 'img/markerPontoE.png';
				case 'P': return 'img/markerPontoP.png';
				default: return 'img/markerPonto.png';
			}
		},

		/**
		 * Função para limpar os dados do mapa, primeiro if serve para identificar quando for do tipo 
		 * sem pontos bloqueia o select para selecionar pontos, demais if e else servem para identificar
		 * quando for edição de uma linha não limpa a informação do mapa, limpa somente após o usuário 
		 * mudar os valores do input tipo.
		 * @author Desconhecido 
		*/
		limparPontos() {
			if (this.tipoPontoSelecionadoNumber == 2) {
				this.$emit('alterarTipoPonto', true);

			}

			if (this.editandoLinhaProp != this.tipoPonto) {
				this.limpaDadosPontos();
			} else if (this.editandoLinhaProp == false) {
				this.limpaDadosPontos();
			} else if (this.inputPontoSelecionado > 1) {
				this.limpaDadosPontos();
			}

		},

		limpaDadosPontos() {
			this.distanciaTotal = '0.00';
			this.tempoEstimado = '00:00:00';
			this.quantidadeDePontos = '0';
			this.pontosLinha = [];
			this.polinhaRastro = [];
			this.checkSelecionados = [];
			this.pontosTotal = [];
			this.$refs.refBuscarPontos.clearAll();
		},

		pontoRetorno(index) {
			this.pontosLinha[index].plretorno = !this.pontosLinha[index].plretorno
			this.$forceUpdate()
		},

		mudaDrag(event) {
			var novoIndex = event.newIndex
			var velhoIndex = event.oldIndex
			if (novoIndex < velhoIndex) {
				this.pontosLinha[novoIndex].plpoordem = (novoIndex + 1)
				this.arrumaOrdemPedidosMais(novoIndex + 1)
			} else if (novoIndex > velhoIndex) {
				this.pontosLinha[novoIndex].plpoordem = (novoIndex + 1)
				this.corrigeOrdemPedidos(velhoIndex, novoIndex)
			}
			for (const index in this.pontosLinha) {
				this.pontosLinha[index].plpoordem = Number(index) + 1
			}
			this.gerarRota()
		},

		arrumaOrdemPedidosMais(index) {
			for (var a in this.pontosLinha) {
				this.pontosLinha[a].plpoordem = parseInt(a) + 1
			}
		},

		corrigeOrdemPedidos(inicio, fim) {
			for (var a = inicio; a <= fim; a++) {
				this.pontosLinha[a].plpoordem = parseInt(a) + 1
			}
		},

		async changeBuscarPontos(value) {
			if (!value.length) return
			let uri = this.baseUri + 'get/ponto'
			let obj = {
				codigo: value,
				ultPonto: value,
			}
			var existemPontos = this.pontosLinha.reduce((teste, ponto) => {
				if (!teste)
					return false
				if (typeof ponto === 'undefined')
					return false
				return true
			}, true)
			if (!existemPontos) return
			var pontosSelecionados = this.pontosLinha
			var data = await new HttpRequest().Post(uri, obj)
			if (data.status) {
				if (_.isArray(data.data.ponto)) {
					if (data.data.ponto.length)
						pontosSelecionados.push(data.data.ponto[0]);
				}
				this.gerarRota()
			}
			for (const index in pontosSelecionados) {
				if (pontosSelecionados[index] === undefined) return
				pontosSelecionados[index].plpoordem = Number(index) + 1
			}
			this.pontosLinha = pontosSelecionados
			this.quantidadeDePontos = this.pontosLinha.length
			this.calculaDistanciaEhora()
			this.$nextTick(() => {
				this.$refs.refBuscarPontos.clearAll()
			})
		},

		async gerarRota() {
			if (!this.pontosLinha.length) return
			this.fecharPopUp()
			let uri = this.baseUri + 'pega/rota'
			this.polinhaRastro = []
			if (this.pontosLinha.length > 1) {
				this.loadingMapa = true
				new HttpRequest().Post(uri, {
					'pontos': this.pontosLinha
				}).then(async (rota) => {
					this.polinhaRastro = await decodePoly.decode(
						rota.data[0].routes[0].geometry
					)
					this.$nextTick(() => {
						this.calculaDistanciaEhora()
						// at(-1) pega o ultimo valor da array, 
						// logo vai ser o ultimo ponto
						this.rotaLinha = rota;
						let velocidade = 0;

						if (this.velocidadeMedia <= 0) {
							velocidade = 40;
						} else {
							velocidade = this.velocidadeMedia;
						}

						this.tempoEstimadoPorPonto(rota.data[0].routes[0].legs, velocidade);
						this.tempoEstimadoTotal(rota.data[0].routes[0].distance, velocidade);
						this.direcionaMapa(rota.data[0].waypoints.at(-1).location)
					})
				}).catch(() => {
					conectionError()
				}).finally(() => {
					this.loadingMapa = false
				})
			}

		},

		/**
		 * Calcula o tempo estimado de entre os pontos e o formata no padrão hh:mm:ss.
		 * @params totalDistance = distancia total da rota | velocidadeMedia = velocidade média informada pelo usuário
		 * @author Otávio 🦆  e Yuri 🇧🇷
		 */
		tempoEstimadoTotal(totalDistance, velocidade) {
			const velocidadeMedia = velocidade * (1000 / 3600);
			const tempoEstimado = totalDistance / velocidadeMedia;

			const horas = Math.floor(tempoEstimado / 3600);
			const minutos = Math.floor((tempoEstimado % 3600) / 60);
			const segundos = Math.floor(tempoEstimado % 60);

			const tempoPercorrido = String(horas).padStart(2, '0') + ':' + String(minutos).padStart(2, '0') + ':' + String(segundos).padStart(2, '0');

			this.tempoEstimado = tempoPercorrido;
		},

		/**
			 * Calcula o tempo estimado de viagem para cada ponto de uma rota e o formata no padrão hh:mm:ss.
		 * @params legs -> retorno do OSMR as informações de cada ponto | | velocidadeMedia = velocidade média informada pelo usuário
		 * @author Otávio 🦆 Yuri 🇧🇷
		 */
		tempoEstimadoPorPonto(legs, velocidade) {
			let tempoAcumulado = 0;

			this.pontosLinha.forEach((ponto, i) => {
				if (i === 0) {
					ponto.pltempoestimado = '00:00:00';
					return;
				}

				const totalDistance = legs[i - 1].annotation.distance.reduce((sum, d) => sum + d, 0);
				const velocidadeMedia = velocidade * (1000 / 3600);
				const tempoEstimado = totalDistance / velocidadeMedia; 

				tempoAcumulado += tempoEstimado;
				const horas = Math.floor(tempoAcumulado / 3600);
				const minutos = Math.floor((tempoAcumulado % 3600) / 60);
				const segundos = Math.floor(tempoAcumulado % 60);

				const tempoFormatado = String(horas).padStart(2, '0') + ':' +
					String(minutos).padStart(2, '0') + ':' +
					String(segundos).padStart(2, '0');

				ponto.pltempoestimado = tempoFormatado;
			});

			this.$forceUpdate();
		},

		/**
		 * adiciona o rastro nas linhas
		 * @author Otávio 🦆 
		 */
		async adicionaRastro() {
			if (this.pontosLinha.length > 1) {
				if (!this.polinhaRastro.length) {
					setTimeout(() => {
						this.adicionaRastro()
					}, 200)
					return
				}
				var rotaSeparada = this.separaRotaEmPontos()
				for (const index in this.pontosLinha) {
					var rota = rotaSeparada[Number(index)]
					this.pontosLinha[Number(index)].plrastro = decodePoly.encode(rota)
				}
				this.$forceUpdate()
			}
		},

		async removerPonto(index) {
			this.fecharPopUp()
			this.pontosLinha.splice(index, 1)
			await this.gerarRota()
			for (const index in this.pontosLinha) {
				this.pontosLinha[index].plpoordem = Number(index) + 1
			}
			this.quantidadeDePontos = this.pontosLinha.length
			this.$nextTick(() => {
				this.adicionaRastro()
			})
		},

		fecharPopUp() {
			if (this.$refs.refMapa) {
				this.$refs.refMapa.closePopup()
			}
		},

		/**
		 * Direciona o usuario para o ponto clicado.
		 * Validando se um ponto novo com uma rota traçada,
		 * ou não. 
		*/
		direcionaMapa(value) {
			let centerMap = null
			if (Array.isArray(value))
				centerMap = { lat: value[1], lng: value[0] }
			else
				centerMap = { lat: value.polatitude, lng: value.polongitude }
			if (this.$refs.refMapa) {
				this.$refs.refMapa.flyTo(centerMap)
			}
		},

		kmMorto(index) {
			this.pontosLinha[index].plconsiderarkmmorta =
				!this.pontosLinha[index].plconsiderarkmmorta
			this.calculaDistanciaEhora(index)
			this.$forceUpdate()
		},

		async editarLinhaPoliEditavel(value) {
			this.polinhaRastro = value;
			this.calculaDistanciaEhora();
			this.polilinhaEditavel = !this.polilinhaEditavel;
			this.$emit('showAlertInfoEdicaoLinha', this.polilinhaEditavel);
		},

		/**
		 * faz o calculo para saber os segundos médios de duração da rota 
		 * depois formata para hh:mm:ss 
		 * @author Otávio 🦆 
		 */
		calcularDistanciaTempoTotal() {
			let duracaoSegundos = '';
			if (this.distanciaTotal > 0 && this.velocidadeMedia > 0) {
				duracaoSegundos = (this.distanciaTotal / this.velocidadeMedia) * 3600;

				let horas = Math.floor(duracaoSegundos / 3600).toString().padStart(2, '0');
				let minutos = Math.floor((duracaoSegundos % 3600) / 60).toString().padStart(2, '0');
				let segundos = Math.floor(duracaoSegundos % 60).toString().padStart(2, '0');
				return `${horas}:${minutos}:${segundos}`;
			}
			return '00:00:00';
		},

		/**
		 * calcula a distancia total da rota e o tempo que vai levar
		 * mesmo o usuario editando pela polilinha
		 * @author Lucas Eduardo
		 */
		calculaDistanciaEhora() {
			this.fecharPopUp()
			var dist = 0
			for (var a in this.polinhaRastro) {
				var b = parseInt(a) + 1;
				if (this.polinhaRastro[b] !== undefined) {
					var aux = distanciaLatLonEmKm(
						this.polinhaRastro[a],
						this.polinhaRastro[b]
					)
					dist = dist + (aux);
				}
			}
			var diTotal = (dist / 1000)
			this.distanciaTotal = diTotal.toFixed(2)
			this.tempoEstimado = this.calcularDistanciaTempoTotal();
			this.$nextTick(() => {
				this.adicionaRastro()
			})
		},

		/**
		 * recebe um numero de 1 (uma) casa e devolve com 2 (duas) casas
		 * @param Number/String
		 * @return String
		 * @author Lucas Eduardo
		 */
		duasCasa(value) {
			value = String(value).split('.')[0]
			if (value.length === 1) {
				return '0' + value
			}
			return value
		},

		/**
		 * É responsavel por procurar o ponto da rota mais 
		 * proximo ao ponto que tu mandou
		 * pode ficar um pouquinho dps do ponto que tu mandou
		 * faz um filtro pela array do rastro que é enviado para a polilinha
		 * e cria uma array que vai ser a rota ate o ponto enviado
		 * @param  Array - [lat, lng]
		 * @return Array - [[lat, lng], [lat, lng]]
		 * @author Lucas Eduardo
		 */
		encontraLatLngMaisProximo(ponto) {
			var retorrno = latLngMaisProximo(ponto, this.polinhaRastro)
			return retorrno.rotaAoPonto
		},

		separaRotaEmPontos() {
			var pontosProximos = []
			var pontosSelecionados = [...this.pontosLinha]
			for (let index in pontosSelecionados) {
				var arrPonto = latLngMaisProximo(
					[
						Number(pontosSelecionados[index].polatitude),
						Number(pontosSelecionados[index].polongitude)
					],
					this.polinhaRastro
				)
				pontosProximos.push(arrPonto)
			}
			var rotaEmParte = []
			var arrr = [...this.polinhaRastro]

			for (const a in pontosProximos) {
				var arrayDeRota = []
				var indArr = 0
				for (const b in arrr) {
					indArr = b
					if (pontosProximos[a].toString() === arrr[b]) {
						break
					}
					arrayDeRota.push(arrr[b])
				}
				arrr.splice(0, indArr)
				rotaEmParte.push(arrayDeRota)
			}
			return rotaEmParte
		},


		/**
		 * Define a imagem que vai aparecer ao lado do nome
		 * do ponto na lista ao lado do mapa.
		 * @param tipo char, deve estar contida nos casos
		 * do switch.
		 * @author Gui 🍺
		 */
		defineImagem(tipo) {
			switch (tipo) {
				case 'E':
					return {
						src: 'img/circle_entrega.png',
						title: 'Entrega',
						alt: 'E',
					}
				case 'C':
					return {
						src: 'img/circle_coleta.png',
						title: 'Coleta',
						alt: 'C',
					}
				case 'P':
					return {
						src: 'img/circle_referencia.png',
						title: 'Referência',
						alt: 'P',
					}
				default:
					break;
			}
		},
	},
})
</script>

<style lang="css">
#tabsLinhasEscolherPontos {
	.back-claro {
		background: white !important;
	}

	.back-escuro {
		background: #6d6e6e0e !important;
	}

	.plretornoBlue {
		color: #48a3e0;
		font-weight: bold;

		&:hover {
			cursor: pointer;
		}
	}

	.rotaciona {
		transform: rotate(180deg);
	}

	.plconsiderarkmmortaBlue {
		color: #48a3e0;
		font-weight: bold;

		&:hover {
			cursor: pointer;
		}
	}

	.marker-pin {
		width: 25px;
		height: 25px;
		border-radius: 50% 50% 50% 0;
		background: #800080;
		position: absolute;
		transform: rotate(-45deg);
		left: 50%;
		top: 50%;
		margin: -15px 0 0 -15px;
	}

	.marker-pin::after {
		margin: 3px 0 0 3px;
		background: #fff;
		position: absolute;
		margin: 4px 4px 4px 4px;
		right: 0;
		margin: 10px auto;
		text-align: center;
		overflow: hidden;
	}

	.centroMarker {
		border-radius: 4000px;
		font-weight: bolder;
		background: white;
		margin: 4px 4px 4px 4px;
		transform: rotate(45deg) !important;
	}

	.borda-linha {
		width: 100%;
	}

	.listaDePontos {
		max-width: 100% !important;
		height: 410px;
		min-height: 410px;
		max-height: 410px;
		overflow-y: auto;
		overflow-x: clip;
		font-size: 12px !important;
		box-sizing: border-box;

		.flex-container {
			border: 1px solid rgba(0, 0, 0, 0.295);
			width: 100%;
			padding: 5px;
			display: flex;
			flex-direction: row;

			.flex-container_1 {
				max-width: 50% !important;
				min-width: 50% !important;
				display: flex;
				align-items: baseline;
			}

			.flex-container_2 {
				max-width: 50% !important;
				min-width: 50% !important;
				align-items: flex-end;
				display: flex;
			}
		}

		.all_botoes {
			border: solid 1px white;
			align-self: center;
		}

		.descricao {
			white-space: nowrap;
			overflow: hidden;
			align-content: flex-start;
			text-overflow: ellipsis;
			margin: 1px;
			cursor: move;
		}

		.index {
			padding: 0px;
			margin: 2px;
			align-self: center;
		}

		.cross::before {
			margin: 2px !important;
			padding: 0 !important;
			cursor: grab;
		}

		.tempoEstimado {
			flex-basis: 40%;
			margin: 0px 1px 0px 2px;
		}

		.botoes::before {
			margin: 1px;
			padding: 1px;
		}

		.botoes {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.divDentroMapa {
		margin-top: -2px;
		margin-left: -20px;
		margin-right: 20px;
		border: 0 0 0 0;
		z-index: 500;

		&:hover {
			cursor: pointer;
		}

		.buttonMap {
			background-color: rgba(255, 255, 255, 0.767);
			opacity: 0.5;
			height: 450px;
			max-width: 60px;
			min-width: 60px;

			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				color: rgb(10, 10, 10);
			}

		}

		.infoMapa {
			transform: translateX(30%);
			z-index: 1;
			position: absolute;
			margin-left: 10px;
			color: rgb(255, 253, 253);
			font-weight: bolder;
			background: gray;
			text-align: left;
			padding-left: 5px;
			min-width: 170px;
			opacity: 1 !important;
		}
	}
}
</style>
